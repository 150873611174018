/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import gpt3Logo from '../../assets/cryptologo.png';
import './footer.css';
// eslint-disable-next-line import/order
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">
        Do you want to step in to the future before others
      </h1>
    </div>

    <Link to="/cryptopage">
      <div className="gpt3__footer-btn">
        <p>Let's Get Started</p>
      </div>
    </Link>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>
          Copyright@2022{' '}
          <a
            href="https://mdakram09.netlify.app/"
            target="_blank"
            style={{ color: 'lightblue' }}
            rel="noreferrer"
          >
            Md Akram Khan
          </a>{' '}
          <br /> All Rights Reserved
        </p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <Link to="/">
          <p>Home</p>
        </Link>
        <Link to="/cryptopage">
          <p>Cryptopage</p>
        </Link>
        <Link to="/news">
          <p>News</p>
        </Link>
        <Link to="/aboutUs">
          <p>About Us</p>
        </Link>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Developer</h4>
        <p>
          <a
            href="https://mdakram09.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            Md Akram Khan
          </a>{' '}
        </p>
        {/* <p>Privacy Policy</p> */}
        <Link to="/contact">
          <p>Contact us</p>
        </Link>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        {/* <p>Crechterwoord K12 182 DK Alknjkcb</p>
        <p>085-132567</p> */}
        <p>iamakramkhan09@gmail.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>
        Copyright@2022{' '}
        <a
          href="https://mdakram09.netlify.app/"
          target="_blank"
          style={{ color: 'lightblue' }}
          rel="noreferrer"
        >
          Md Akram Khan
        </a>
        . All rights reserved.
      </p>
    </div>
  </div>
);

export default Footer;
