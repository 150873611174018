import React, { Fragment, useState } from 'react';
import './AboutUs.css';

const AboutUs = () => {
  const [toggleTab, setToggleTab] = useState(1);
  const siteUrl = window.location.href;
  if (siteUrl.includes('aboutUs')) {
    document.title = 'About US - Cryptojar';
  }
  const toggleState = (index) => {
    setToggleTab(index);
  };
  return (
    <>
      <section className="about">
        <div className="row">
          <div className="column">
            {toggleTab === 1 ? (
              <div className="about-img" />
            ) : (
              <div className="aboutDeveloper-img" />
            )}
          </div>

          <div className="column">
            <div className="tabs">
              <div
                className={
                  toggleTab === 1 ? 'single-tab active-tab' : 'single-tab'
                }
                onClick={() => toggleState(1)}
              >
                <h2>About Us</h2>
              </div>

              {/* <div
                className={
                  toggleTab === 2 ? 'single-tab active-tab' : 'single-tab'
                }
                onClick={() => toggleState(2)}
              >
                <h2>Tech Stack Used</h2>
              </div> */}

              <div
                className={
                  toggleTab === 3 ? 'single-tab active-tab' : 'single-tab'
                }
                onClick={() => toggleState(3)}
              >
                <h2>About Developer</h2>
              </div>
            </div>

            <div className="tab-content">
              {/* About Content */}

              <div
                className={
                  toggleTab === 1 ? 'content active-content' : 'content'
                }
              >
                <h2>About Us</h2>
                <p>
                  CryptoJar is a platform which shows data related to different
                  Crypto Currencies, Graphs replated to price hikes and price
                  downs, News related to Crypto Currencies and many more.
                </p>
                <h3>What Do We Offer ?</h3>
                <p>
                  The fall and rise in prices of all Crypto Currencies are now
                  at you finger tips. Just click on some links in the site and
                  hurrah!, you will get diiferent data about different Cryptos,
                  Insights regarding their prices, News related to Crypto
                  Currencies around the world and many more.
                </p>
                <h3>From Where Do We Get Cryptocurrency Related Data ?</h3>
                <p>
                  The Data related to crypto currencies are fetched from Coin
                  Ranking API which is a trusted platform for data integrity and
                  the News Articles related to crypto currencies are fetched
                  from Bing News API which is also a trusted platform for data
                  integrity.
                </p>
              </div>

              {/* Skills Content */}

              <div
                className={
                  toggleTab === 2 ? 'content active-content' : 'content'
                }
              >
                <h2>Skills</h2>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Eveniet nostrum laborum porro pariatur minima tenetur.
                  Adipisci obcaecati placeat ex veritatis est delectus sunt,
                  quam doloribus nemo sed perferendis ipsa corporis?
                </p>

                <div className="skills-row">
                  <div className="skills-column">
                    <div className="progress-wrap">
                      <h3>Developer</h3>
                      <div className="progress">
                        <div className="progress-bar">
                          <span>80%</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="skills-column">
                    <div className="progress-wrap">
                      <h3>Designer</h3>
                      <div className="progress">
                        <div className="progress-bar Designer">
                          <span>90%</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="skills-column">
                    <div className="progress-wrap">
                      <h3>Javascript</h3>
                      <div className="progress">
                        <div className="progress-bar Javascript">
                          <span>85%</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="skills-column">
                    <div className="progress-wrap">
                      <h3>PhotoShop</h3>
                      <div className="progress">
                        <div className="progress-bar PhotoShop">
                          <span>88%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Experience Content */}

              <div
                className={
                  toggleTab === 3 ? 'content active-content' : 'content'
                }
              >
                <div className="exp-column">
                  <h2>Who Is The Developer Of This CryptoJar Website ?</h2>
                  <a
                    href="https://mdakram09.netlify.app/#/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Md Akram Khan</span>
                  </a>
                  <p>
                    My self Md Akram Khan, I am a Full Stack Web Developer
                    Skilled in ReactJS, Django, Java, JavaScript, Python and
                    many more. <br />
                    <br />I am a B.Tech(CSE) Graduate(2022 Pass Out batch) and I
                    Am Currently Working In A FinTech Sass Company from past 7
                    months.
                    <br />I want to make a carrier in Software Development and
                    especially in Frontend Web Development. <br />
                    <br />
                    To know more about me please go to my portfolio website and
                    feel free to contact me anytime by my Email Address and
                    LinkedIn listed below.
                    <br />
                    <br />
                    Email : <span>iamakramkhan09@gmail.com</span>
                    <br />
                    LinkedIn :{' '}
                    <a
                      href="https://www.linkedin.com/in/mdakram09/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>https://www.linkedin.com/in/mdakram09/</span>
                    </a>
                    <br />
                    Portfolio Website:{' '}
                    <a
                      href="https://mdakram09.netlify.app/#/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>https://mdakram09.netlify.app/#/</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
