/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { Divider } from '@material-ui/core';
import moment from 'moment';
import NewsIcon from '../../assets/newsIcon.webp';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '20.5rem',
    height: '20rem',
    backgroundColor: 'var(--color-blog)',
    '&:hover': {
      boxShadow: '-1px 10px 29px 0px rgba(77, 127, 183, 0.8)',
    },
    color: 'white',
  },
  text: {
    color: 'white',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  blueText: {
    color: 'var(--color-text)',
  },
  cryptoIcon: {
    height: '50px',
    width: '50px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    height: '6rem',
  },
  cardContent: {
    height: '12rem',
  },
  readMore: {
    marginLeft: '1rem',
    color: 'var(--color-text)',
  },
  cardFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: '1rem',
    paddingBottom: '3rem',
    height: '1rem',
  },
  providerIcon: {
    height: '2rem',
    width: '2rem',
    borderRadius: '50%',
  },
  provider: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function NewsCard(props) {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={7}>
      <CardHeader
        className={classes.cardHeader}
        avatar={{
          ...(
            <img
              className={classes.cryptoIcon}
              src={props.image || NewsIcon}
              alt="icon"
            />
          ),
        }}
        title={`${props.name.substring(0, 90)}...`}
        subheader={
          // eslint-disable-next-line react/jsx-wrap-multilines
          {
            ...(
              <Typography className={classes.blueText}>
                {moment(props.datePublished).startOf('ss').fromNow()}
              </Typography>
            ),
          }
        }
      />

      <Divider />
      <CardContent className={classes.cardContent}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.text}
        >
          {`${props.description?.substring(0, 300)}...`}
        </Typography>

        <br />
      </CardContent>
      <div className={classes.cardFooter}>
        <a href={`${props.newsUrl}`} target="_blank" rel="noreferrer">
          <Typography className={classes.readMore}>Read More</Typography>
        </a>
        <div className={classes.provider}>
          <Typography style={{ fontSize: '0.8rem', marginRight: '0.3rem' }}>
            {props.fullProvider}
          </Typography>
          <img
            className={classes.providerIcon}
            src={props.provider || NewsIcon}
            alt="icon"
          />
        </div>
      </div>
    </Card>
  );
}
