import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';

const sagaMiddleWare = createSagaMiddleware();

const middleware = [sagaMiddleWare];

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  {},
  // eslint-disable-next-line comma-dangle
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleWare.run(rootSaga);

export default store;
