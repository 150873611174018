import { getCoinsActions } from './actionConstants';

export const fetchCoins = (data) => ({
  type: getCoinsActions.FETCH_COINS,
  payload: data,
});

export const fetchCoinsStart = (data) => ({
  type: getCoinsActions.FETCH_COINS_START,
  payload: data,
});

export const fetchCoinsSuccess = (data) => ({
  type: getCoinsActions.FETCH_COINS_SUCCESS,
  payload: data,
});

export const fetchCoinsFailed = (data) => ({
  type: getCoinsActions.FETCH_COINS_FAIL,
  payload: data,
});
