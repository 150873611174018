/* eslint-disable comma-dangle */
import axios from 'axios';

export const getData = async () => {
  const apiKey = process.env.REACT_APP_CRYPTO_API;
  const response = await axios

    .get('https://coinranking1.p.rapidapi.com/coins', {
      headers: {
        'x-rapidapi-host': 'coinranking1.p.rapidapi.com',

        'x-rapidapi-key': `${apiKey}`,
      },
    })

    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Err: ', err);
    });
  // eslint-disable-next-line no-return-await
  return response;
};

export const getCoin = async (coinName, limit) => {
  const apiKey = process.env.REACT_APP_CRYPTO_API;
  const response = await axios

    .get(
      `https://coinranking1.p.rapidapi.com/coins/?search=${coinName}&limit=${limit}`,
      {
        headers: {
          'x-rapidapi-host': 'coinranking1.p.rapidapi.com',

          'x-rapidapi-key': `${apiKey}`,
        },
      }
    )

    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Err: ', err);
    });
  // eslint-disable-next-line no-return-await
  return response;
};

// return await axios.get(url);

export const getCryptoDetails = async (coinId) => {
  const response = await axios

    // eslint-disable-next-line no-useless-concat
    .get(`https://coinranking1.p.rapidapi.com/coin/${coinId}`, {
      headers: {
        'x-rapidapi-host': 'coinranking1.p.rapidapi.com',

        'x-rapidapi-key': '5468d5af60msh6a3353500e5e83dp13a3d5jsnc704c286bf21',
      },
    })

    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Err: ', err);
    });

  // eslint-disable-next-line no-return-await
  return response;
};

export const Coin = (coin) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  `https://coinranking1.p.rapidapi.com/coin/${coin}`;
