/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Article from '../../components/article/Article';

// import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const useStyles = makeStyles(() => ({
  showMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4rem',
  },
  showMoreButtonStyles: {
    flex: '0.5',
    width: '100%',
    minHeight: '3.125rem',
    fontFamily: ' var(--font-family)',
    fontWeight: '400',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    background: '#FF4820',
    border: '0.125rem solid #FF4820',
    padding: '0 1rem',
    color: '#fff',
    cursor: 'pointer',
    outline: 'none',

    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
}));
const Blog = () => {
  const classes = useStyles();
  return (
    <>
      <div className="gpt3__blog section__padding" id="blog">
        <div className="gpt3__blog-heading">
          <h1 className="gradient__text">
            A lot is happening, <br /> We are blogging about it.
          </h1>
        </div>

        <div className="gpt3__blog-container">
          <div className="gpt3__blog-container_groupA">
            <a
              href="https://techexpress.netlify.app/blogs/what-is-cryptocurrency-and-how-it-works"
              target="_blank"
              rel="noreferrer"
              aria-label="link"
            >
              <Article
                imgUrl="https://cdn.sanity.io/images/qv8p0th0/production/9b010f5390c68b1a9879ef971017e3406e6a40bb-6048x4024.jpg?rect=0,0,6048,4023&w=1920&h=1277&auto=format"
                date="Jan 14, 2023"
                text="What is Cryptocurrency and How it Works ?"
                paragraph="Cryptocurrency is a digital payment system that doesn't rely on banks to verify transactions. It’s a peer-to-peer system that can enable anyone anywhere to send and receive payments. Instead of being physical money carried around and exchanged in the real world, cryptocurrency payments exist purely as digital entries to an online database describing specific transactions. When you transfer cryptocurrency funds, the transactions are recorded in a public ledger. Cryptocurrency is stored in digital wallets.

Cryptocurrency received its name because it uses encryption to verify transactions. This means advanced coding is involved in storing and transmitting cryptocurrency data between wallets and to public ledgers..."
              />
            </a>
          </div>
          <div className="gpt3__blog-container_groupB">
            <a
              href="https://techexpress.netlify.app/blogs/how-to-buy-cryptocurrency"
              target="_blank"
              rel="noreferrer"
              aria-label="link"
            >
              <Article
                imgUrl="https://cdn.sanity.io/images/qv8p0th0/production/29d2f5bb37711cba78a48ca2dcb28fe8caea8848-4000x2667.jpg?w=1920&h=1280&auto=format"
                date="Jan 14, 2023"
                text="How to buy cryptocurrency?
"
                paragraph="You may be wondering how to buy cryptocurrency safely. There are typically three steps involved. These are:

Step 1: Choosing a platform
Step 2: Funding your account
Step 3: Placing an order...
"
              />
            </a>
            <a
              href="https://techexpress.netlify.app/blogs/how-to-store-cryptocurrency"
              target="_blank"
              rel="noreferrer"
              aria-label="link"
            >
              <Article
                imgUrl="https://logoisus.com/wp-content/uploads/2021/09/executium-rhm7H8X5J98-unsplash_small.jpg"
                date="Jan 14, 2023"
                text="How to Store Cryptocurrency?"
                paragraph="Once you have purchased cryptocurrency, you need to store it safely to protect it from hacks or theft..."
              />
            </a>
            <a
              href="https://techexpress.netlify.app/blogs/what-can-you-buy-with-cryptocurrency"
              target="_blank"
              rel="noreferrer"
              aria-label="link"
            >
              <Article
                imgUrl="https://cdn.sanity.io/images/qv8p0th0/production/c63936df62acab231cd2bee09a31f3fe39d4a253-4000x2667.jpg?w=1920&h=1280&auto=format"
                date="Jan 14, 2023"
                text="What Can You Buy With Cryptocurrency?"
                paragraph="When it was first launched, Bitcoin was intended to be a medium for daily transactions, making it possible to buy everything from a cup of coffee to a computer or even big-ticket..."
              />
            </a>
            <a
              href="https://techexpress.netlify.app/blogs/cryptocurrency-fraud-and-cryptocurrency-scams"
              target="_blank"
              rel="noreferrer"
              aria-label="link"
            >
              <Article
                imgUrl="https://cdn.sanity.io/images/qv8p0th0/production/2dd5b3d454fedc77f587a971512063b4b46dc7ef-5134x3423.jpg?w=1920&h=1280&auto=format"
                date="Jan 14, 2023"
                text="Cryptocurrency Fraud and Scams"
                paragraph="Cryptocurrency criminals promote non-existent opportunities to invest in digital currencies and create the illusion of huge returns by paying off old investors with new investors..."
              />
            </a>
          </div>
        </div>
        <a
          href="https://techexpress.netlify.app/categories/cryptocurrency"
          target="_blank"
          rel="noreferrer"
        >
          <div className={classes.showMoreButton}>
            <Button className={classes.showMoreButtonStyles}>Show More</Button>
          </div>
        </a>
      </div>
    </>
  );
};

export default Blog;
