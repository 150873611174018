// import * as types from '../actions/statsActions';

// const initialState = {
//   stats: [],
//   error: null,
//   loading: false,
// };

// const statsReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case types.FETCH_GLOBAL_STATS_START:
//       return {
//         ...state,
//         loading: true,
//       };
//     case types.FETCH_GLOBAL_STATS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         stats: action.payload,
//       };
//     case types.FETCH_GLOBAL_STATS_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default statsReducer;

import { statsActions } from '../actions/actionConstants';

const initialState = {
  stats: [],
  coins: [],
  error: null,
  loading: false,
};

const {
  FETCH_GLOBAL_STATS_START,
  FETCH_GLOBAL_STATS_SUCCESS,
  FETCH_GLOBAL_STATS_FAILED,
} = statsActions;

// eslint-disable-next-line import/prefer-default-export
export const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GLOBAL_STATS_START:
      return { ...state, loading: true, error: false };

    case FETCH_GLOBAL_STATS_SUCCESS:
      return {
        loading: false,
        stats: [action.payload.data.stats],
        coins: [action.payload.data.coins],
        error: false,
      };

    case FETCH_GLOBAL_STATS_FAILED:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};
