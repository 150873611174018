/* eslint-disable comma-dangle */
import axios from 'axios';

export const graphData = async (coinId, timeperiod) => {
  const apiKey = process.env.REACT_APP_CRYPTO_API;
  const response = await axios

    .get(
      `https://coinranking1.p.rapidapi.com/coin/${coinId}/history?referenceCurrencyUuid=yhjMzLPhuIDl&timePeriod=${timeperiod}`,
      {
        headers: {
          'x-rapidapi-host': 'coinranking1.p.rapidapi.com',

          'x-rapidapi-key': `${apiKey}`,
        },
      }
    )

    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Err: ', err);
    });
  // eslint-disable-next-line no-return-await

  return response;
};
