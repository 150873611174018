import { graphActions } from '../actions/actionConstants';

const initialState = {
  gdata: [],
  error: null,
  loading: false,
};

const {
  FETCH_GRAPH_DATA_START,
  FETCH_GRAPH_DATA_SUCCESS,
  FETCH_GRAPH_DATA_FAILED,
} = graphActions;

// eslint-disable-next-line import/prefer-default-export
export const graphReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GRAPH_DATA_START:
      return { ...state, loading: true, error: false };

    case FETCH_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        gdata: action.payload,
        error: false,
      };

    case FETCH_GRAPH_DATA_FAILED:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};
