/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'var(--color-bg)',
    margin: '0.1rem 0rem 0.1rem 0rem',
  },
  iconStyles: {
    color: 'white',
  },
  valueText: {
    color: 'var(--color-text)',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

export default function SimpleList(props) {
  const classes = useStyles();
  const { icon, title, value } = props;

  return (
    <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem button>
          <ListItemIcon className={classes.iconStyles}>{icon}</ListItemIcon>

          <ListItemText primary={title} />
          <ListItemText className={classes.valueText} primary={value} />
        </ListItem>
      </List>
      <Divider />
    </div>
  );
}
