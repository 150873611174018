/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-fragments */
import React, { useLayoutEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import {
  Footer,
  Blog,
  Possibility,
  // Features,
  WhatGPT3,
  Header,
} from './containers';
import { CTA, Navbar } from './components';
import Carousal from './components/carousal/Carousal';

import Homepage from './containers/HomepageCryptoSection/HomepageStats';
import CryptoPage from './containers/CryptocurrencyPageContainer/CryptoPage';

import './App.css';
import CryptoDetails from './components/CryptoDetails/CryptoDetails';
// eslint-disable-next-line no-unused-vars
import NewsFunction from './components/news/News';
import AboutUs from './components/about/AboutUs';
// import Chart from './components/charts/Chart';
import ContactUs from './components/contact/ContactUs';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => (
  <div className="App">
    <Wrapper>
      <Routes>
        <Route
          path="/"
          element={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div className="gradient__bg">
              <Navbar />
              <Header />

              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}

              <Carousal />
              <Homepage />
              <WhatGPT3 />
              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}
              <Possibility />
              <CTA />
              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}
              <Blog />
              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}
              <Footer />
            </div>
          }
        />

        <Route
          path="/cryptoPage"
          element={
            <>
              <Navbar />
              <CryptoPage />
              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}
              <Footer />
            </>
          }
        />

        <Route
          exact
          path="/cryptodetails/:coinId"
          element={
            <>
              <Navbar />
              <CryptoDetails />
              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}
              <Footer />
            </>
          }
        />

        <Route
          path="/news"
          element={
            <>
              <Navbar />
              <NewsFunction />
              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}
              <Footer />
            </>
          }
        />

        <Route
          path="/aboutUs"
          element={
            <>
              <Navbar />
              <AboutUs />
              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}
              <Footer />
            </>
          }
        />

        <Route
          path="/contact"
          element={
            <>
              <Navbar />
              <ContactUs />
              {/* Google Ads Section Starts */}
              <script
                async
                src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7193368543726116"
                crossOrigin="anonymous"
              />
              <ins
                className="adsbygoogle"
                // eslint-disable-next-line react/style-prop-object
                style={{ display: 'block' }}
                data-ad-client="ca-pub-7193368543726116"
                data-ad-slot="6412788500"
                data-ad-format="auto"
                data-full-width-responsive="true"
              />
              <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
              </script>
              {/* Google Ads Section Endss */}
              <Footer />
            </>
          }
        />
      </Routes>
    </Wrapper>
  </div>
);

export default App;
