/* eslint-disable import/order */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/cryptologo.png';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <Link to="/">
          <div className="gpt3__navbar-links_logo">
            <img src={logo} />
          </div>
        </Link>
        <div className="gpt3__navbar-links_container">
          <p>
            <Link to="/">Home</Link>
          </p>
          <p>
            <Link to="/cryptopage">Cryptocurrencies</Link>
          </p>
          <p>
            <Link to="/news">News</Link>
          </p>
          <p>
            <a
              href="https://techexpress.netlify.app/categories/cryptocurrency"
              target="_blank"
              rel="noreferrer"
            >
              Blogs
            </a>
          </p>
          <p>
            <Link to="/aboutUs">About Us</Link>
          </p>
          <p>
            <Link to="/contact">Contact Us</Link>
          </p>
          <p>
            <a
              href="https://www.buymeacoffee.com/CC6Tm4ZZIy"
              target="_blank"
              to="/contact"
              rel="noreferrer"
            >
              Donate
            </a>
          </p>
        </div>
      </div>
      {/* <div className="gpt3__navbar-sign">
        <p>Sign in</p>
        <button type="button">Sign up</button>
      </div> */}
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <p>
                <Link to="/" onClick={() => setToggleMenu(false)}>
                  Home
                </Link>
              </p>
              <p>
                <Link to="/cryptopage" onClick={() => setToggleMenu(false)}>
                  Cryptocurrencies
                </Link>
              </p>
              <p>
                <Link to="/news" onClick={() => setToggleMenu(false)}>
                  News
                </Link>
              </p>
              <p>
                <a
                  href="https://techexpress.netlify.app/categories/cryptocurrency"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => setToggleMenu(false)}
                >
                  Blogs
                </a>
              </p>
              <p>
                <Link to="/aboutUs" onClick={() => setToggleMenu(false)}>
                  About Us
                </Link>
              </p>
              <p>
                <Link to="/contact" onClick={() => setToggleMenu(false)}>
                  Contact Us
                </Link>
              </p>
              <p>
                <a
                  href="https://www.buymeacoffee.com/CC6Tm4ZZIy"
                  target="_blank"
                  onClick={() => setToggleMenu(false)}
                  rel="noreferrer"
                >
                  Donate
                </a>
              </p>
            </div>
            {/* <div className="gpt3__navbar-menu_container-links-sign">
              <p>Sign in</p>
              <button type="button">Sign up</button>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
