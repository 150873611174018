/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '90%',
    backgroundColor: 'var(--color-bg)',
    '& .MuiListItem-gutters': {
      paddingLeft: '0rem !important',
      paddingRight: '0rem !important',
    },
  },
  iconStyles: {
    color: 'white',
  },
  valueText: {
    color: 'var(--color-text)',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

export default function LinksLists(props) {
  const classes = useStyles();
  const { title, value, url } = props;

  return (
    <div className={classes.root}>
      <a href={url} target="_blank" rel="noreferrer">
        <List component="nav" aria-label="main mailbox folders">
          <ListItem button>
            <ListItemText
              primary={title.charAt(0).toUpperCase() + title.slice(1)}
            />
            <ListItemText
              className={classes.valueText}
              primary={value.slice(8)}
            />
          </ListItem>
        </List>
      </a>
      <Divider />
    </div>
  );
}
