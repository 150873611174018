/* eslint-disable operator-linebreak */
import { getCoinsActions } from '../actions/actionConstants';

const initialState = {
  data: {
    coins: [],
  },

  error: null,
  loading: false,
};

const { FETCH_COINS_START, FETCH_COINS_SUCCESS, FETCH_COINS_FAILED } =
  getCoinsActions;

// eslint-disable-next-line import/prefer-default-export
export const getCoinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COINS_START:
      return { ...state, loading: true, error: false };

    case FETCH_COINS_SUCCESS:
      return {
        // ...state,
        data: {
          // ...state.data,
          coins: [...action?.payload?.coins],
        },
        loading: false,
        // coins: [action.payload],
        error: false,
      };

    case FETCH_COINS_FAILED:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};
