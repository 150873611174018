import React from 'react';
import possibilityImage from '../../assets/104.png';
import './possibility.css';
// eslint-disable-next-line import/order
import { Link } from 'react-router-dom';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Get the Crypto Details at your finger tips</h4>
      <h1 className="gradient__text">
        The possibilities are <br /> beyond your imagination
      </h1>
      <p>
        The fall and rise in prices of all Crypto Currencies are now at you
        finger tips. Just click on some links in the site and hurrah!, you will
        get diiferent data about different Cryptos, Insights regarding their
        prices, News related to Crypto Currencies around the world and many
        more.
      </p>
      <Link to="/cryptopage">
        <h4>Explore Crypto Currencies</h4>
      </Link>
    </div>
  </div>
);

export default Possibility;
