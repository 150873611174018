import { graphActions } from './actionConstants';

export const fetchGraph = (data) => ({
  type: graphActions.FETCH_GRAPH_DATA,
  payload: data,
});

export const fetchGraphStart = (data) => ({
  type: graphActions.FETCH_GRAPH_DATA_START,
  payload: data,
});

export const fetchGraphSuccess = (data) => ({
  type: graphActions.FETCH_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const fetchGraphFailed = (data) => ({
  type: graphActions.FETCH_GRAPH_DATA_FAIL,
  payload: data,
});
