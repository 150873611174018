// import * as types from './actionTypes';

// const initialState = {
//   details: [],
//   error: null,
//   loading: false,
// };

// const cryptoDetailsReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case types.FETCH_CRYPTO_DETAILS_START:
//       return {
//         ...state,
//         loading: true,
//       };
//     case types.FETCH_CRYPTO_DETAILS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         cryptoDetails: action.payload,
//       };
//     case types.FETCH_CRYPTO_DETAILS_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default cryptoDetailsReducer;

import { cryptoDetailsActions } from '../actions/actionConstants';

const initialState = {
  cryptoDetails: [],
  error: null,
  loading: false,
};

const {
  FETCH_CRYPTO_DETAILS_START,
  FETCH_CRYPTO_DETAILS_SUCCESS,
  FETCH_CRYPTO_DETAILS_FAILED,
} = cryptoDetailsActions;

// eslint-disable-next-line import/prefer-default-export
export const cryptoDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CRYPTO_DETAILS_START:
      return { ...state, loading: true, error: false };

    case FETCH_CRYPTO_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        cryptoDetails: action.payload,
        error: false,
      };

    case FETCH_CRYPTO_DETAILS_FAILED:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};
