import React from 'react';
// import people from '../../assets/people.png';
// import ai from '../../assets/ai.png';
import headerImage from '../../assets/headerImage.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">
        Let&apos;s Conquer The World of Crypto Currencies with CryptoJar
      </h1>
      <p>
        CryptoJar is a platform which shows data related to different Crypto
        Currencies, Graphs replated to price hikes and price downs, News related
        to Crypto Currencies and many more.
      </p>

      {/* <div className="gpt3__header-content__input">
        <input type="email" placeholder="Send Your Feedback" />
        <button type="button">Send</button>
      </div> */}

      {/* <div className="gpt3__header-content__people">
        <img src={people} />
        <p>856 people have visited this website in past 24 hours</p>
      </div> */}
    </div>

    <div className="gpt3__header-image">
      <img src={headerImage} />
    </div>
  </div>
);

export default Header;
