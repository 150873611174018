import { fork, takeLatest, all, call, put } from 'redux-saga/effects';
import {
  fetchCryptoDetailsStart,
  fetchCryptoDetailsSuccess,
  fetchCryptoDetailsFailed,
} from '../actions/cryptoDetailsAction';
// import config from '../api/config';
// import { EIPP_CONTEXT_PATH } from '../api/constants';
import { getCryptoDetails } from '../api';

function* workerForFetchCryptoDetails(action) {
  try {
    yield put(fetchCryptoDetailsStart());
    const { coinId } = action.payload;

    const response = yield call(getCryptoDetails, coinId);

    yield put(fetchCryptoDetailsSuccess(response?.data));
  } catch (err) {
    yield put(fetchCryptoDetailsFailed());
  }
}

function* watcherForFetchCryptoDetails() {
  yield takeLatest('FETCH_CRYPTO_DETAILS', workerForFetchCryptoDetails);
}

export default function* cryptoDetailsSaga() {
  yield all([fork(() => watcherForFetchCryptoDetails())]);
}
