/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable arrow-body-style */
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

// import { useEffect, useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';

const Carousel = () => {
  const useStyles = makeStyles(() => ({
    carousel: {
      height: '50%',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '4rem',
    },
    carouselItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      textTransform: 'uppercase',
      color: 'white',
      fontFamily: 'Helvetica',
    },
  }));

  const classes = useStyles();

  const coins = useSelector((state) => state.stats?.coins[0]);
  const loading = useSelector((state) => state.stats.loading);

  const loader = () => (
    <div>
      <Skeleton animation="wave" variant="circular" width={40} height={40} />

      <Skeleton
        animation="wave"
        height={30}
        width="80%"
        style={{ marginBottom: 6 }}
      />

      <Skeleton
        animation="wave"
        height={30}
        width="80%"
        style={{ marginBottom: 6 }}
      />
    </div>
  );

  if (loading) return loader();

  const symbol = '$';
  const items = coins?.map((coin) => {
    const profit = coin?.change >= 0;

    return (
      <Link className={classes.carouselItem} to="/">
        <img
          src={coin?.iconUrl}
          alt={coin?.name}
          height="80"
          style={{ marginBottom: 10 }}
        />

        <span>
          {coin?.name}
          &nbsp;
          <span
            style={{
              color: profit > 0 ? 'rgb(14, 203, 129)' : 'red',
              fontWeight: 500,
            }}
          >
            {profit && '+'}
            {coin?.change}%
          </span>
        </span>

        <span style={{ fontSize: 22, fontWeight: 500 }}>
          {symbol} {Number(coin?.price).toFixed(2)}
        </span>
      </Link>
    );
  });

  const responsive = {
    0: {
      items: 2,
    },
    512: {
      items: 4,
    },
  };

  return (
    <div className={classes.carousel}>
      <AliceCarousel
        mouseTracking
        infinite
        autoPlayInterval={1000}
        animationDuration={1500}
        disableDotsControls
        disableButtonsControls
        responsive={responsive}
        items={items}
        autoPlay
      />
    </div>
  );
};

export default Carousel;
