/* eslint-disable arrow-parens */
import { Button, Typography, Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { red } from '@material-ui/core/colors';
import './homepageStats.css';
import { useSelector, useDispatch } from 'react-redux';
import millify from 'millify';
import Skeleton from '@material-ui/lab/Skeleton';
import NewsCard from '../../components/news/NewsCard';
import { fetchNews } from '../../redux/actions/newsAction';
import CryptocurrencyCards from '../../components/CryptoCards/CryptocurrencyCards';

import { fetchGlobalStats } from '../../redux/actions/statsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  showMore: {
    textDecoration: 'none',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#5a25ed',
    marginTop: '0 px',
  },
  homepageHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '40px',
  },
  statsText: {
    color: 'var(--color-text)',
    display: 'flex',
    justifyContent: 'unset',
  },
  showMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4rem',
  },
  showMoreButtonStyles: {
    flex: '0.5',
    width: '100%',
    minHeight: '3.125rem',
    fontFamily: ' var(--font-family)',
    fontWeight: '400',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    background: '#FF4820',
    border: '0.125rem solid #FF4820',
    padding: '0 1rem',
    color: '#fff',
    cursor: 'pointer',
    outline: 'none',

    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  text: {
    color: 'white',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  blueText: {
    color: 'var(--color-text)',
  },
  cryptoIcon: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
  },
  cardRoot: {
    maxWidth: 345,
    backgroundColor: 'var(--color-blog)',
    '&:hover': {
      boxShadow: '-1px 10px 29px 0px rgba(77, 127, 183, 0.8)',
    },
    color: 'white',
  },
}));

const Homepage = () => {
  const classes = useStyles();

  document.title = 'Cryptojar';

  const oldStats = {
    total: 22710,
    total24hVolume: 27184620909,
    totalCoins: 22710,
    totalExchanges: 184,
    totalMarketCap: 812038743591,
    totalMarkets: 34404,
  };

  const stats = useSelector((state) => state.stats?.stats[0]) ?? oldStats;
  const coins = useSelector((state) => state.stats?.coins[0]) ?? [];
  const news = useSelector((state) => state?.news?.news?.data);

  const loading = useSelector((state) => state.stats.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGlobalStats());
  }, []);

  useEffect(() => {
    // eslint-disable-next-line object-shorthand
    dispatch(fetchNews({ name: 'cryptocurrency', symbol: 'crypto' }));
  }, []);

  const loader = () => (
    <div>
      <Container id="section1">
        <Typography variant="h4" className="gradient__text">
          Global Crypto Stats
        </Typography>
        <br />

        <div className={classes.root}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.statsText}
          >
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total Cryptocurrency</Typography>

              <br />

              <Skeleton
                animation="wave"
                height={30}
                width="80%"
                style={{
                  marginBottom: 6,
                  marginLeft: 15,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total Exchanges</Typography>
              <br />

              <Skeleton
                animation="wave"
                height={30}
                width="80%"
                style={{ marginBottom: 6, marginLeft: 15 }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total Market Cap:</Typography>
              <br />

              <Skeleton
                animation="wave"
                height={30}
                width="80%"
                style={{ marginBottom: 6, marginLeft: 15 }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total 24h Volume</Typography>
              <br />

              <Skeleton
                animation="wave"
                height={30}
                width="80%"
                style={{ marginBottom: 6, marginLeft: 15 }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total Markets</Typography>
              <br />

              <Skeleton
                animation="wave"
                height={30}
                width="80%"
                style={{ marginBottom: 6, marginLeft: 15 }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} />
          </Grid>
        </div>

        <div className={classes.homepageHeading}>
          <Typography variant="h4" className="gradient__text">
            Top 10 Cryptos In The World
          </Typography>
          <Link to="cryptopage">
            <Typography className="showMoreText"> Show more </Typography>
          </Link>
        </div>
        <br />

        <Grid
          container
          spacing={3}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            paddingTop: '1rem',
          }}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((currency) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={currency.uuid}>
              <Card className={classes.cardRoot} elevation={7}>
                <CardHeader
                  avatar={{
                    ...(
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    ),
                  }}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                  subheader={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    {
                      ...(
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      ),
                    }
                  }
                />

                <Divider />
                <CardContent>
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />

                  <br />

                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />

                  <br />

                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                  <br />

                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Link to="/cryptopage">
          <div className={classes.showMoreButton}>
            <Button className={classes.showMoreButtonStyles}>Show More</Button>
          </div>
        </Link>
      </Container>
    </div>
  );

  if (loading) return loader();

  return (
    <div>
      <Container id="section1">
        <Typography variant="h4" className="gradient__text">
          Global Crypto Stats
        </Typography>
        <br />

        <div className={classes.root}>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={classes.statsText}
          >
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total Cryptocurrency</Typography>

              <br />

              <Typography variant="h6">{stats?.total}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total Exchanges</Typography>
              <br />

              <Typography variant="h6">{stats?.totalExchanges}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total Markets</Typography>
              <br />

              <Typography variant="h6">{stats?.totalMarkets}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total 24h Volume</Typography>
              <br />

              <Typography variant="h6">
                ${millify(stats?.total24hVolume)}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3}>
              <Typography>Total Market Capital</Typography>
              <br />

              <Typography variant="h6">
                ${millify(stats?.totalMarketCap)}{' '}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3} lg={3} />
          </Grid>
        </div>

        <div className={classes.homepageHeading}>
          <Typography variant="h4" className="gradient__text">
            Top 10 Cryptos In The World
          </Typography>
          <Link to="cryptopage">
            <Typography className="showMoreText"> Show more </Typography>
          </Link>
        </div>
        <br />

        <Grid
          container
          spacing={3}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            paddingTop: '1rem',
          }}
        >
          {coins?.slice(0, 10)?.map((currency) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={currency.uuid}>
              <CryptocurrencyCards
                key={currency.id}
                name={currency.name}
                rank={currency.rank}
                icon={currency.iconUrl}
                price={currency.price}
                marketCap={currency.marketCap}
                change={currency.change}
                uuid={currency.uuid}
              />
            </Grid>
          ))}
        </Grid>
        <Link to="/cryptopage">
          <div className={classes.showMoreButton}>
            <Button className={classes.showMoreButtonStyles}>Show More</Button>
          </div>
        </Link>

        <div className={classes.homepageHeading}>
          <Typography variant="h4" className="gradient__text">
            Top News Related To Cryptocurrencies
          </Typography>
          <Link to="/news">
            <Typography className="showMoreText"> Show more </Typography>
          </Link>
        </div>
        <br />
        <br />
        <Grid
          container
          spacing={3}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          {news?.map((newsP) => (
            <Grid item xs={12} sm={6} md={3} lg={4} key={newsP?.name}>
              <NewsCard
                key={newsP?.title}
                fullProvider={newsP?.publishData[0]?.publisherName}
                name={newsP?.title}
                description={newsP?.description}
                image={newsP?.urlToImage}
                newsUrl={newsP.url}
                datePublished={newsP.publishData[0]?.publishedAt}
                loading={loading}
                className={classes.cardRoot}
              />
            </Grid>
          ))}
        </Grid>
        <Link to="/news">
          <div className={classes.showMoreButton}>
            <Button className={classes.showMoreButtonStyles}>Show More</Button>
          </div>
        </Link>
      </Container>
    </div>
  );
};

export default Homepage;
