import { fork, takeLatest, all, call, put } from 'redux-saga/effects';
import {
  fetchCoinsStart,
  fetchCoinsSuccess,
  fetchCoinsFailed,
} from '../actions/getCoinsActions';

import { getCoin } from '../api';

function* workerForfetchCoins(action) {
  try {
    yield put(fetchCoinsStart());

    const { coinName, limit } = action.payload;

    const response = yield call(getCoin, coinName, limit);

    yield put(fetchCoinsSuccess(response?.data?.data));
  } catch (err) {
    yield put(fetchCoinsFailed());
  }
}

function* watcherForfetchCoins() {
  yield takeLatest('FETCH_COINS', workerForfetchCoins);
}

export default function* getCoinsSaga() {
  yield all([fork(() => watcherForfetchCoins())]);
}
