// import * as types from './actionTypes';

// const initialState = {
//   news: [],
//   error: null,
//   loading: false,
// };

// const newsReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case types.FETCH_NEWS_START:
//       return {
//         ...state,
//         loading: true,
//       };
//     case types.FETCH_NEWS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         news: action.payload,
//       };
//     case types.FETCH_NEWS_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     default:
//       return state;
//   }
// };

// export default newsReducer;

import { newsActions } from '../actions/actionConstants';

const initialState = {
  news: [],
  error: null,
  loading: false,
};

const { FETCH_NEWS_START, FETCH_NEWS_SUCCESS, FETCH_NEWS_FAILED } = newsActions;

// eslint-disable-next-line import/prefer-default-export
export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NEWS_START:
      return { ...state, loading: true, error: false };

    case FETCH_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: action.payload,
        error: false,
      };

    case FETCH_NEWS_FAILED:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};
