import axios from 'axios';

export const getNews = async (params) => {
  const { name, symbol } = params;
  const baseURL = process.env.REACT_APP_NEWSJAR_API_BASE_URL;
  const response = await axios

    .post(
      // eslint-disable-next-line comma-dangle
      `${baseURL}/getCryptoNews?name=${name}&symbol=${symbol}`
    )

    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log('Err: ', err);
    });
  // eslint-disable-next-line no-return-await
  return response;
};
