import { all } from 'redux-saga/effects';
import globalStatsSaga from './statsSaga';
import newsSaga from './newsSaga';
import cryptoDetailsSaga from './cryptoDetailsSaga';
import graphDataSaga from './graphDataSaga';
import getCoinsSaga from './getCoinsSaga';

export default function* rootSaga() {
  yield all([
    globalStatsSaga(),
    newsSaga(),
    cryptoDetailsSaga(),
    graphDataSaga(),
    getCoinsSaga(),
  ]);
}
