import React, { Fragment } from 'react';
import './ContactUs.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  spanText: {
    color: '#18dcff',
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const siteUrl = window.location.href;
  if (siteUrl.includes('contact')) {
    document.title = 'Contact US - Cryptojar';
  }
  return (
    <>
      <section className="about">
        <div className="row">
          <div className="column">
            <div className="contactUs-img" />
          </div>

          <div className="column">
            <div className="tab-content">
              <div className="content active-content">
                <h2>Contact Us</h2>
                <p>
                  CryptoJar is a platform which shows data related to different
                  Crypto Currencies, Graphs replated to price hikes and price
                  downs, News related to Crypto Currencies and many more.
                </p>
                <h3>Feel Free To Connect With Us At Any Moment</h3>
                <p>
                  Email :{' '}
                  <span className={classes.spanText}>
                    iamakramkhan09@gmail.com
                  </span>
                  <br />
                  LinkedIn :{' '}
                  <a
                    href="https://www.linkedin.com/in/mdakram09/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className={classes.spanText}>
                      https://www.linkedin.com/in/mdakram09/
                    </span>
                  </a>
                  <br />
                  Portfolio Website:{' '}
                  <a
                    href="https://mdakram09.netlify.app/#/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className={classes.spanText}>
                      https://mdakram09.netlify.app/#/
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
