import { fork, takeLatest, all, call, put } from 'redux-saga/effects';
import {
  fetchGraphStart,
  fetchGraphSuccess,
  fetchGraphFailed,
} from '../actions/graphActions';
// import config from '../api/config';
// import { EIPP_CONTEXT_PATH } from '../api/constants';
import { graphData } from '../api/graphDataAPI';

function* workerForFetchGraphData(action) {
  try {
    yield put(fetchGraphStart());
    const response = yield call(
      // eslint-disable-next-line comma-dangle
      graphData,
      action.payload.coinId,
      // eslint-disable-next-line comma-dangle
      action.payload.time
    );

    yield put(fetchGraphSuccess(response?.data));
  } catch (err) {
    yield put(fetchGraphFailed());
  }
}

function* watcherForFetchGraphData() {
  yield takeLatest('FETCH_GRAPH_DATA', workerForFetchGraphData);
}

export default function* graphDataSaga() {
  yield all([fork(() => watcherForFetchGraphData())]);
}
