import { statsActions } from './actionConstants';

export const fetchGlobalStats = (data) => ({
  type: statsActions.FETCH_GLOBAL_STATS,
  payload: data,
});

export const fetchGlobalStatsStart = (data) => ({
  type: statsActions.FETCH_GLOBAL_STATS_START,
  payload: data,
});

export const fetchGlobalStatsSuccess = (data) => ({
  type: statsActions.FETCH_GLOBAL_STATS_SUCCESS,
  payload: data,
});

export const fetchGlobalStatsFailed = (data) => ({
  type: statsActions.FETCH_GLOBAL_STATS_FAIL,
  payload: data,
});
