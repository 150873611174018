import { cryptoDetailsActions } from './actionConstants';

export const fetchCryptoDetails = (data) => ({
  type: cryptoDetailsActions.FETCH_CRYPTO_DETAILS,
  payload: data,
});

export const fetchCryptoDetailsStart = (data) => ({
  type: cryptoDetailsActions.FETCH_CRYPTO_DETAILS_START,
  payload: data,
});

export const fetchCryptoDetailsSuccess = (data) => ({
  type: cryptoDetailsActions.FETCH_CRYPTO_DETAILS_SUCCESS,
  payload: data,
});

export const fetchCryptoDetailsFailed = (data) => ({
  type: cryptoDetailsActions.FETCH_CRYPTO_DETAILS_FAIL,
  payload: data,
});
