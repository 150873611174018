/* eslint-disable operator-linebreak */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import './cryptocurrencyCards.css';
import millify from 'millify';

import { Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    backgroundColor: 'var(--color-blog)',
    '&:hover': {
      boxShadow: '-1px 10px 29px 0px rgba(77, 127, 183, 0.8)',
    },
    color: 'white',
  },
  text: {
    color: 'white',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  blueText: {
    color: 'var(--color-text)',
  },
  cryptoIcon: {
    height: '3.125rem',
    width: '3.125rem',
    borderRadius: '50%',
  },
}));

const current = new Date();
const date = `${current.getDate()}/${
  current.getMonth() + 1
}/${current.getFullYear()}`;

export default function CryptocurrencyCards(props) {
  const classes = useStyles();

  const oldCoinsData = {
    uuid: 'Qwsogvtv82FCd',
    icon: 'https://cdn.coinranking.com/bOabBYkcX/bitcoin_btc.svg',
    name: 'Bitcoin',
    price: '16639.489691677536',
    marketCap: '319805367727',
    change: '-0.42',
  };

  const { uuid, icon, rank, name, price, marketCap, change } =
    props ?? oldCoinsData;

  const siteUrl = window.location.href;

  if (siteUrl.includes('cryptopage')) {
    document.title = 'Cryptocurrencies - Cryptojar';
  }

  // eslint-disable-next-line react/destructuring-assignment
  const profit = props.change >= 0;

  return (
    <Link to={`/cryptodetails/${uuid}`}>
      <Card className={classes.root} elevation={7}>
        <CardHeader
          avatar={{
            ...(<img className={classes.cryptoIcon} src={icon} alt="icon" />),
          }}
          title={name}
          subheader={
            // eslint-disable-next-line react/jsx-wrap-multilines
            {
              ...(<Typography className={classes.blueText}>{date}</Typography>),
            }
          }
        />

        <Divider />
        <CardContent>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.text}
          >
            Rank: {rank}
          </Typography>

          <br />
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.text}
          >
            Price: ${millify(price || 0)}
          </Typography>

          <br />

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.text}
          >
            Market Capital: ${millify(marketCap || 0)}
          </Typography>

          <br />

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            className={classes.text}
          >
            Daily Change:{' '}
            <span
              style={{
                color: profit > 0 ? 'rgb(14, 203, 129)' : 'rgb(255, 99, 71)',
                fontWeight: 500,
              }}
            >
              {profit && '+'}
              {change}%
            </span>{' '}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
}
