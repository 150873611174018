import { fork, takeLatest, all, call, put } from 'redux-saga/effects';
import {
  fetchGlobalStatsStart,
  fetchGlobalStatsSuccess,
  fetchGlobalStatsFailed,
} from '../actions/statsActions';

import { getData } from '../api';

function* workerForFetchGlobalStats() {
  try {
    yield put(fetchGlobalStatsStart());
    const response = yield call(getData);

    yield put(fetchGlobalStatsSuccess(response?.data));
  } catch (err) {
    yield put(fetchGlobalStatsFailed());
  }
}

function* watcherForFetchGlobalStats() {
  yield takeLatest('FETCH_GLOBAL_STATS', workerForFetchGlobalStats);
}

export default function* globalStatsSaga() {
  yield all([fork(() => watcherForFetchGlobalStats())]);
}
