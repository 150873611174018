import { combineReducers } from 'redux';
import { statsReducer } from './statsReducer';
import { cryptoDetailsReducer } from './cryptoDetailsReducer';
import { newsReducer } from './newsReducer';
import { graphReducer } from './graphReducer';
import { getCoinsReducer } from './getCoinsReducer';

const rootReducer = combineReducers({
  stats: statsReducer,
  cryptoDetails: cryptoDetailsReducer,
  news: newsReducer,
  gdata: graphReducer,
  coinData: getCoinsReducer,
});

export default rootReducer;
