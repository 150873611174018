export const statsActions = {
  FETCH_GLOBAL_STATS: 'FETCH_GLOBAL_STATS',
  FETCH_GLOBAL_STATS_START: 'FETCH_GLOBAL_STATS_START',
  FETCH_GLOBAL_STATS_SUCCESS: 'FETCH_GLOBAL_STATS_SUCCESS',
  FETCH_GLOBAL_STATS_FAIL: 'FETCH_GLOBAL_STATS_FAIL',
};

export const getCoinsActions = {
  FETCH_COINS: 'FETCH_COINS',
  FETCH_COINS_START: 'FETCH_COINS_START',
  FETCH_COINS_SUCCESS: 'FETCH_COINS_SUCCESS',
  FETCH_COINS_FAIL: 'FETCH_COINS_FAIL',
};

export const cryptoDetailsActions = {
  FETCH_CRYPTO_DETAILS: 'FETCH_CRYPTO_DETAILS',
  FETCH_CRYPTO_DETAILS_START: 'FETCH_CRYPTO_DETAILS_START',
  FETCH_CRYPTO_DETAILS_SUCCESS: 'FETCH_CRYPTO_DETAILS_SUCCESS',
  FETCH_CRYPTO_DETAILS_FAIL: 'FETCH_CRYPTO_DETAILS_FAIL',
};

export const newsActions = {
  FETCH_NEWS: 'FETCH_NEWS',
  FETCH_NEWS_START: 'FETCH_NEWS_START',
  FETCH_NEWS_SUCCESS: 'FETCH_NEWS_SUCCESS',
  FETCH_NEWS_FAIL: 'FETCH_NEWS_FAIL',
};

export const graphActions = {
  FETCH_GRAPH_DATA: 'FETCH_GRAPH_DATA',
  FETCH_GRAPH_DATA_START: 'FETCH_GRAPH_DATA_START',
  FETCH_GRAPH_DATA_SUCCESS: 'FETCH_GRAPH_DATA_SUCCESS',
  FETCH_GRAPH_DATA_FAIL: 'FETCH_GRAPH_DATA_FAIL',
};
