/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import MenuItem from '@material-ui/core/MenuItem';
// import CircularIndeterminate from '../../components/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { fetchGraph } from '../../redux/actions/graphActions';

import * as types from '../../redux/actions/actionConstants';
// import { graphData } from '../../redux/api/graphDataAPI';

const Graph = () => {
  const useStyles = makeStyles((theme) => ({
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
      // justifyContent: 'center',
      marginTop: 25,
      // padding: 40,

      [theme.breakpoints.down('md')]: {
        width: '100%',
        height: '100vw',
        marginTop: 0,
        padding: 0,
        paddingTop: 0,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100vh',
        marginTop: 0,
        padding: 0,
        paddingTop: 0,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '100vh',
        marginTop: 0,
        padding: 0,
        paddingTop: 0,
      },
    },
    dropdownStyles: {
      backgroundColor: 'white',
      color: 'black',
      marginLeft: '5vw',
      width: '4rem',
      display: 'flex',
      justifyContent: 'center',
    },
  }));

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    // eslint-disable-next-line comma-dangle
    Legend
  );

  const classes = useStyles();
  const { coinId } = useParams();
  const [time, setTime] = useState('24h');

  const allData = useSelector(
    // eslint-disable-next-line comma-dangle
    (state) => state?.gdata?.gdata?.data?.history
  );

  const dispatch = useDispatch();
  const handleChange = (event) => {
    setTime(event.target.value);
  };
  const timeperiods = ['3h', '24h', '7d', '30d', '3m', '1y', '3y', '5y'];

  const myGraphData = [];
  const timeStamp = [];

  for (let i in allData) {
    myGraphData.unshift(allData?.[i]?.price);
    timeStamp.unshift(new Date(allData[i].timestamp * 1000).toLocaleString());
  }

  useEffect(() => {
    if (coinId) {
      // eslint-disable-next-line object-shorthand
      dispatch(fetchGraph({ coinId: coinId, time: time }));
    }
  }, [time]);

  return (
    <div>
      <div className={classes.dropdownStyles}>
        {' '}
        <FormControl>
          <Select
            displayEmpty
            value={time}
            onChange={handleChange}
            className={classes.selectEmpty}
            renderValue={() => {
              // eslint-disable-next-line no-constant-condition
              if (true) {
                return <em>{time}</em>;
              }

              return ', ';
            }}
            inputProps={{
              name: 'age',
              id: 'age-native-simple',
            }}
          >
            {timeperiods?.map((myTime) => (
              <MenuItem key={myTime.uuid} value={myTime}>
                {myTime}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className={classes.container}>
        <Line
          style={{ width: '100vw', height: '100vh', paddingRight: '0.8rem' }}
          data={{
            labels: timeStamp,
            datasets: [
              {
                label: 'Price in USD($)',
                data: myGraphData,
                fill: true,
                borderColor: '#742774',
              },
            ],
          }}
          options={{
            elements: {
              point: {
                radius: 1,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default Graph;
