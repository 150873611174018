import { newsActions } from './actionConstants';

export const fetchNews = (data) => ({
  type: newsActions.FETCH_NEWS,
  payload: data,
});

export const fetchNewsStart = (data) => ({
  type: newsActions.FETCH_NEWS_START,
  payload: data,
});

export const fetchNewsSuccess = (data) => ({
  type: newsActions.FETCH_NEWS_SUCCESS,
  payload: data,
});

export const fetchNewsFailed = (data) => ({
  type: newsActions.FETCH_NEWS_FAIL,
  payload: data,
});
