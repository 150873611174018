import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';
// eslint-disable-next-line import/order
import { Link } from 'react-router-dom';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
    <div className="gpt3__whatgpt3-feature">
      <Feature
        title="What is CryptoJar"
        text="CryptoJar is a platform which shows data related to different Crypto Currencies, Graphs replated to price hikes and price downs, News related to Crypto Currencies and many more."
      />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">
        The possibilities are beyond your imagination
      </h1>
      <Link to="/cryptopage">
        <p>Explore Crypto Currencies</p>
      </Link>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature
        title="Data"
        text="The Data related to crypto currencies are fetched from Coin Ranking API which is a trusted platform for data integrity."
      />
      <Feature
        title="News Articles"
        text="The News Articles related to crypto currencies are fetched from Bing News API which is a trusted platform for data integrity."
      />
      <Feature
        title="Exchange Details"
        text="The Exchange Details related to crypto currencies are fetched from Coin Ranking API which is a trusted platform for data integrity."
      />
    </div>
  </div>
);

export default WhatGPT3;
