/* eslint-disable comma-dangle */
/* eslint-disable operator-linebreak */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, makeStyles, Container } from '@material-ui/core';
import millify from 'millify';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import BeenhereOutlinedIcon from '@material-ui/icons/BeenhereOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import ImportExportOutlinedIcon from '@material-ui/icons/ImportExportOutlined';
import BlurCircularOutlinedIcon from '@material-ui/icons/BlurCircularOutlined';
import Grid from '@material-ui/core/Grid';

// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-unresolved
import ReactHtmlParser from 'react-html-parser';
import CircularProgress from '@material-ui/core/CircularProgress';
import NewsCard from '../news/NewsCard';
import { fetchCryptoDetails } from '../../redux/actions/cryptoDetailsAction';
import Graph from './Graph';
import { fetchNews } from '../../redux/actions/newsAction';
import SimpleList from './ListItems';
import LinksLists from './LinksLists';

// eslint-disable-next-line import/no-duplicates

const CryptoDetails = () => {
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      overflow: 'hidden',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5rem',
        marginBottom: '3rem',
      },
      fontFamily: ' var(--font-family)',
      marginBottom: '1rem',
    },
    containerForCryptoDescription: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '5rem',
        marginBottom: '3rem',
        marginLeft: '1rem',
      },
      fontFamily: ' var(--font-family)',
      marginBottom: '1rem',
    },
    priceCharDiv: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        marginBottom: '1rem',
      },
      fontFamily: ' var(--font-family)',
      marginTop: '1rem',
      justifyContent: 'space-around',
    },
    priceCharDivForNews: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        marginBottom: '1rem',
      },
      fontFamily: ' var(--font-family)',
      marginTop: '1rem',
    },
    cryptoTitle: {
      width: '50%',
    },
    priceChartStats: {
      display: 'flex',
      gap: '4rem',
      color: 'white',
    },
    sidebar: {
      width: '30%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 25,
      marginBottom: 25,
      borderRight: '2px solid grey',
      color: 'white',
    },
    heading: {
      fontWeight: 'bold',
      marginBottom: 20,
      fontFamily: ' var(--font-family)',
    },
    description: {
      width: '100%',
      fontFamily: ' var(--font-family)',
      padding: 25,
      paddingBottom: 15,
      paddingTop: 0,
      textAlign: 'justify',
    },
    marketData: {
      alignSelf: 'start',
      padding: 25,
      paddingTop: 10,
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'space-around',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        alignItems: 'start',
      },
    },
    spinnerClass: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    },
    readMore: {
      color: 'var(--color-text)',
    },
    otherStats: {
      color: 'white',
      backgroundColor: 'green',
      height: '100vh',
      width: '50%',
    },
    cryptoValueStats: {
      color: 'white',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    allCryptoStats: {
      color: 'white',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cryptoLinks: {
      color: 'white',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    headingTag: {
      color: 'white',
      display: 'grid',
      alignItems: 'center',
      textAlign: 'center',
      marginBottom: '2rem',
    },
    gridRoot: {
      flexGrow: 1,
    },
    containerClass: {
      marginBottom: '3rem',
      marginTop: '3rem',
    },
  }));

  const classes = useStyles();

  const oldDetails = {
    '24hVolume': '11232444643',
    allTimeHigh: { price: '68763.41083248306', timestamp: 1636502400 },
    btcPrice: '1',
    change: '-0.16',
    coinrankingUrl: 'https://coinranking.com/coin/Qwsogvtv82FCd+bitcoin-btc',
    color: '#f7931A',
    description:
      '<p>Bitcoin is the first digital currency that allows users to send and receive money, without the interference of a central bank or government. Instead, a network of thousands of peers is controlling the transactions; a decentralized system.',
    fullyDilutedMarketCap: '347035875419',
    iconUrl: 'https://cdn.coinranking.com/bOabBYkcX/bitcoin_btc.svg',

    listedAt: 1330214400,
    lowVolume: false,
    marketCap: '317614867972',
    name: 'Bitcoin',
    notices: null,
    numberOfExchanges: 142,
    numberOfMarkets: 4022,
    price: '16525.517877072856',
    priceAt: 1672547460,
    rank: 1,
    symbol: 'BTC',
    tags: (2)[('layer-1', 'proof-of-work')],
    tier: 1,
    uuid: 'Qwsogvtv82FCd',
    websiteUrl: 'https://bitcoin.org',
  };

  const { coinId } = useParams();
  // eslint-disable-next-line operator-linebreak
  const cryptoDetails =
    useSelector(
      // eslint-disable-next-line comma-dangle
      (state) => state?.cryptoDetails?.cryptoDetails?.data?.coin
    ) ?? oldDetails;

  const volumeOfValue = +Object.values(cryptoDetails)[12];

  // eslint-disable-next-line no-unused-vars
  const stats = [
    {
      title: 'Price to USD',
      value: `$ ${cryptoDetails?.price && millify(cryptoDetails?.price)}`,
      icon: <MonetizationOnOutlinedIcon />,
    },
    {
      title: 'Rank',
      value: cryptoDetails?.rank,
      icon: <BeenhereOutlinedIcon />,
    },
    {
      title: '24h Volume',
      value: `$ ${volumeOfValue && millify(volumeOfValue)}`,
      icon: <ExploreOutlinedIcon />,
    },
    {
      title: 'Market Cap',
      value: `$ ${
        cryptoDetails?.marketCap && millify(cryptoDetails?.marketCap)
      }`,
      icon: <ShoppingCartOutlinedIcon />,
    },
    {
      title: 'All Time High(Daily Avg.)',
      value: `$ ${
        cryptoDetails?.allTimeHigh?.price &&
        millify(cryptoDetails?.allTimeHigh?.price)
      }`,
      icon: <TrackChangesOutlinedIcon />,
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const genericStats = [
    {
      title: 'Number Of Markets',
      value: cryptoDetails?.numberOfMarkets,
      icon: <AccountBalanceOutlinedIcon />,
    },
    {
      title: 'Number Of Exchanges',
      value: cryptoDetails?.numberOfExchanges,
      icon: <LocalMallOutlinedIcon />,
    },
    {
      title: 'Aprroved Supply',
      value: cryptoDetails?.supply?.confirmed ? (
        <DoneOutlinedIcon />
      ) : (
        <CloseOutlinedIcon />
      ),
      icon: <LocalShippingOutlinedIcon />,
    },
    {
      title: 'Total Supply',
      value: `$ ${
        cryptoDetails?.supply?.total && millify(cryptoDetails?.supply?.total)
      }`,
      icon: <ImportExportOutlinedIcon />,
    },
    {
      title: 'Circulating Supply',
      value: `$ ${
        cryptoDetails?.supply?.circulating &&
        millify(cryptoDetails?.supply?.circulating)
      }`,
      icon: <BlurCircularOutlinedIcon />,
    },
  ];

  const loading = useSelector((state) => state?.cryptoDetails?.loading);
  const news = useSelector((state) => state?.news?.news?.data);
  const profit = cryptoDetails?.change >= 0;

  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line object-shorthand
    dispatch(fetchCryptoDetails({ coinId: coinId }));
  }, []);

  const crypto = cryptoDetails?.name;
  const symbol = cryptoDetails?.symbol;

  useEffect(() => {
    // eslint-disable-next-line object-shorthand
    dispatch(fetchNews({ name: crypto, symbol }));
  }, [crypto]);

  if (loading) {
    return (
      <div className={classes.spinnerClass}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div>
        <div className={classes.headingTag}>
          <Typography variant="h4" className="gradient__text">
            {cryptoDetails?.name}
          </Typography>

          <div
            className="gpt3__header-content"
            style={{
              alignItems: 'center',
              marginRight: '0rem',
              marginTop: '0rem',
            }}
          >
            <p style={{ marginTop: '0rem' }}>
              {cryptoDetails?.name} Live price in US dollars. View value
              statistics, market cap and supply.
            </p>
          </div>
        </div>

        <div className={classes.container}>
          <div className={classes.cryptoValueStats}>
            <Typography variant="h5" className="gradient__text">
              {cryptoDetails?.name} Value Statistics
            </Typography>
            <div
              className="gpt3__header-content"
              style={{
                alignItems: 'center',
                marginRight: '0rem',
                marginTop: '0rem',
                textAlign: 'center',
              }}
            >
              <p style={{ marginTop: '0rem' }}>
                An overview showing the stats of {cryptoDetails?.name}
              </p>
            </div>

            {stats.map(({ icon, title, value }) => (
              <SimpleList icon={icon} title={title} value={value} />
            ))}
          </div>

          <div className={classes.allCryptoStats}>
            <Typography variant="h5" className="gradient__text">
              Other Stats
            </Typography>
            <div
              className="gpt3__header-content"
              style={{
                alignItems: 'center',
                marginRight: '0rem',
                marginTop: '0rem',
                textAlign: 'center',
              }}
            >
              <p style={{ marginTop: '0rem' }}>
                An overview showing the stats of all cryptocurrencies{' '}
                {cryptoDetails?.name}
              </p>
            </div>
            {genericStats.map(({ icon, title, value }) => (
              <SimpleList icon={icon} title={title} value={value} />
            ))}
          </div>
        </div>
        <div className={classes.priceCharDiv}>
          <Typography variant="h5" className="gradient__text">
            {cryptoDetails?.name} Price Chart
          </Typography>
          <div className={classes.priceChartStats}>
            <Typography>
              Change:{' '}
              <span
                style={{
                  color: profit > 0 ? 'rgb(14, 203, 129)' : 'red',
                  fontWeight: 500,
                }}
              >
                {profit && '+'}
                {cryptoDetails?.change} %{' '}
              </span>
            </Typography>
            <Typography>
              Current price: ${millify(cryptoDetails?.price || 0)}
            </Typography>
          </div>
        </div>

        <div className={classes.container}>
          <Graph />
        </div>

        <Container>
          <div className={classes.priceCharDivForNews}>
            <Typography variant="h4" className="gradient__text">
              Latest News About {cryptoDetails?.name}
            </Typography>
          </div>
        </Container>
        <Container className={classes.containerClass}>
          <Grid
            container
            spacing={3}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            {news?.map((newsP) => (
              <Grid item xs={12} sm={6} md={3} lg={4} key={newsP?.name}>
                <NewsCard
                  key={newsP?.title}
                  fullProvider={newsP?.publishData[0]?.publisherName}
                  name={newsP?.title}
                  description={newsP?.description}
                  image={newsP?.urlToImage}
                  newsUrl={newsP.url}
                  datePublished={newsP.publishData[0]?.publishedAt}
                  loading={loading}
                  className={classes.cardRoot}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
        <div className={classes.containerForCryptoDescription}>
          <div className="cryptoDescription">
            <h3>What is {cryptoDetails.name}?</h3>
            <p>{ReactHtmlParser(cryptoDetails.description)}</p>
          </div>

          <div className={classes.cryptoLinks}>
            <Typography variant="h5" className="gradient__text">
              {cryptoDetails?.name} Links
            </Typography>
            {cryptoDetails.links?.map((link) => (
              <LinksLists title={link.type} value={link.url} url={link.url} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoDetails;
