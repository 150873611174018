/* eslint-disable comma-dangle */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
import { Typography, Divider } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '@material-ui/lab/Skeleton';
import { red } from '@material-ui/core/colors';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
// import InfiniteScroll from 'react-infinite-scroll-component';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
// import CircularProgress from '@material-ui/core/CircularProgress';
import CryptocurrencyCards from '../../components/CryptoCards/CryptocurrencyCards';
import { fetchCoins } from '../../redux/actions/getCoinsActions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  showMore: {
    textDecoration: 'none',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#5a25ed',
    marginTop: '0 px',
  },
  homepageHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statsText: {
    color: 'var(--color-text)',
  },
  text: {
    color: 'white',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  blueText: {
    color: 'var(--color-text)',
  },
  cryptoIcon: {
    height: '50px',
    width: '50px',
    borderRadius: '50%',
  },
  cardRoot: {
    maxWidth: 345,
    backgroundColor: 'var(--color-blog)',
    '&:hover': {
      boxShadow: '-1px 10px 29px 0px rgba(77, 127, 183, 0.8)',
    },
    color: 'white',
  },
  papperRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  searchBar: {
    display: 'flex',
    justifyContent: 'center',
  },
  infiniteScrollInner: {
    overflow: 'hidden !important',
  },
  infiniteScrollContainer: {
    height: '100%',
    width: '100%',
    overflow: 'visible !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const CryptoPage = () => {
  const classes = useStyles();

  const oldCoinData = [
    {
      uuid: 'Qwsogvtv82FCd',
      symbol: 'BTC',
      name: 'Bitcoin',
      color: '#f7931A',
      iconUrl: 'https://cdn.coinranking.com/bOabBYkcX/bitcoin_btc.svg',
      marketCap: '319802577351',
      price: '16639.344508281818',
      listedAt: 1330214400,
      tier: 1,
      change: '-0.43',
      rank: 1,
      sparkline: [
        '16718.582629883746',
        '16718.726617878216',
        '16721.9313634614',
        '16730.993086204475',
        '16713.70759745182',
        '16692.149728989156',
        '16683.742220130298',
        '16679.413871276447',
        '16680.203719572455',
        '16686.127964261937',
        '16686.54727308443',
        '16717.99071841195',
        '16715.2075159388',
        '16719.337716247657',
        '16719.798476274853',
        '16732.22436360971',
        '16727.736173809048',
        '16724.207373575915',
        '16718.05817501671',
        '16721.743375495334',
        '16722.861632736814',
        '16663.571501198516',
        '16652.89442970701',
        '16636.55031403751',
        '16636.211012118838',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/Qwsogvtv82FCd+bitcoin-btc',
      '24hVolume': '13348025907',
      btcPrice: '1',
    },
    {
      uuid: 'razxDUgYGNAdQ',
      symbol: 'ETH',
      name: 'Ethereum',
      color: '#3C3C3D',
      iconUrl: 'https://cdn.coinranking.com/rk4RKHOuW/eth.svg',
      marketCap: '147314088985',
      price: '1207.2555796915024',
      listedAt: 1438905600,
      tier: 1,
      change: '-0.89',
      rank: 2,
      sparkline: [
        '1217.786050229179',
        '1217.4819270840699',
        '1218.1411103802227',
        '1217.673313987979',
        '1215.865226482106',
        '1215.3887352707782',
        '1214.388116468701',
        '1214.1403526615138',
        '1214.391132768127',
        '1214.70992512455',
        '1214.4359755753937',
        '1216.6731500391934',
        '1216.0348166622973',
        '1215.6799514779393',
        '1215.6653375123028',
        '1216.8369396632759',
        '1215.9163673828425',
        '1215.3728465032398',
        '1215.7348706615842',
        '1216.1403072658125',
        '1215.82308859004',
        '1210.1994191998606',
        '1209.628547954997',
        '1208.9394172884784',
        '1208.0263338306227',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/razxDUgYGNAdQ+ethereum-eth',
      '24hVolume': '3822269490',
      btcPrice: '0.07255427514531124',
    },
    {
      uuid: 'HIVsRcGKkPFtW',
      symbol: 'USDT',
      name: 'Tether USD',
      color: '#22a079',
      iconUrl: 'https://cdn.coinranking.com/mgHqwlCLj/usdt.svg',
      marketCap: '65347520007',
      price: '0.999768048128185',
      listedAt: 1420761600,
      tier: 1,
      change: '-0.02',
      rank: 3,
      sparkline: [
        '0.9997503002529933',
        '0.9995481743778664',
        '0.9995737166059626',
        '0.9999438240261777',
        '1.0004681792155674',
        '1.0003460965819162',
        '1.0004872996503715',
        '0.9999978803051585',
        '0.9998917704659911',
        '0.9996636859084276',
        '0.9994444757985691',
        '0.9986738175641212',
        '0.999686934054461',
        '0.9998944176087938',
        '1.0000174043643997',
        '0.9995189124897856',
        '0.9997870200253876',
        '0.9999962487866825',
        '0.9999296415788393',
        '0.9997443309410102',
        '0.9995946576834386',
        '1.0004782656136235',
        '0.9999067470600634',
        '1.0003621692363138',
        '0.9999219592640474',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/HIVsRcGKkPFtW+tetherusd-usdt',
      '24hVolume': '15593471999',
      btcPrice: '0.000060084581314521',
    },
    {
      uuid: 'aKzUVe4Hh_CON',
      symbol: 'USDC',
      name: 'USDC',
      color: '#7894b4',
      iconUrl: 'https://cdn.coinranking.com/jkDf8sQbY/usdc.svg',
      marketCap: '43793281348',
      price: '1.0003496217592196',
      listedAt: 1539043200,
      tier: 1,
      change: '0.01',
      rank: 4,
      sparkline: [
        '0.9999624279255828',
        '0.9998198465634897',
        '0.9997198740667098',
        '1.000007115754042',
        '1.000683009885547',
        '1.000428412058697',
        '1.0005813163867139',
        '1.0001452269959734',
        '1.0000643917890542',
        '0.9998544183060418',
        '0.9997442950207323',
        '0.9989453537583968',
        '0.9999038618996011',
        '1.0001103733078376',
        '1.000262766640779',
        '0.9998435948884807',
        '1.0000698166043074',
        '1.0003233312303312',
        '1.0003327467846899',
        '1.0001481238221062',
        '0.9999020119807781',
        '1.0008804210940823',
        '1.0002205187445699',
        '1.000679434165521',
        '1.0003515699166043',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/aKzUVe4Hh_CON+usdc-usdc',
      '24hVolume': '2068708027',
      btcPrice: '0.000060119533029761',
    },
    {
      uuid: 'WcwrkfNI4FUAe',
      symbol: 'BNB',
      name: 'BNB',
      color: '#e8b342',
      iconUrl: 'https://cdn.coinranking.com/B1N19L_dZ/bnb.svg',
      marketCap: '34997994845',
      price: '245.05747712954698',
      listedAt: 1503014400,
      tier: 1,
      change: '-0.61',
      rank: 5,
      sparkline: [
        '246.46610006508777',
        '246.6848454993893',
        '247.10881492394998',
        '247.01001017351294',
        '246.25832805570764',
        '245.81015124830964',
        '245.6601794818348',
        '245.49550441226998',
        '245.5732957579809',
        '245.67412036192093',
        '245.50222679171074',
        '246.25258469630563',
        '246.15865357150662',
        '246.1393216955086',
        '245.9775892568567',
        '246.08471663735932',
        '245.82871189653636',
        '245.73999896194812',
        '245.45046097402576',
        '245.44449753466554',
        '245.70573050985968',
        '245.29671549969126',
        '245.24169756396628',
        '245.13014091811016',
        '245.15884664745136',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/WcwrkfNI4FUAe+bnb-bnb',
      '24hVolume': '406674312',
      btcPrice: '0.014727592003854224',
    },
    {
      uuid: '-l8Mn2pVlRs-p',
      symbol: 'XRP',
      name: 'XRP',
      color: '#000000',
      iconUrl: 'https://cdn.coinranking.com/B1oPuTyfX/xrp.svg',
      marketCap: '17228246692',
      price: '0.34251848477893976',
      listedAt: 1421798400,
      tier: 1,
      change: '-2.38',
      rank: 6,
      sparkline: [
        '0.3505878110342053',
        '0.35231686831106124',
        '0.3534307664166416',
        '0.35166998966777185',
        '0.3490405355370515',
        '0.34747547837657256',
        '0.34518907535625665',
        '0.34393528366039927',
        '0.34273062120789916',
        '0.34333218970642343',
        '0.3448860212412174',
        '0.3488540216789565',
        '0.3486405718682114',
        '0.34830161564224216',
        '0.345640141297787',
        '0.34655397363136226',
        '0.3461324225127652',
        '0.3447022804870588',
        '0.3447655718001566',
        '0.34573880896079445',
        '0.345806536383538',
        '0.34308949347987017',
        '0.34282785323790776',
        '0.34259639821575444',
        '0.3423826989764683',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/-l8Mn2pVlRs-p+xrp-xrp',
      '24hVolume': '1010115996',
      btcPrice: '0.000020584854445946',
    },
    {
      uuid: 'vSo2fu9iE1s0Y',
      symbol: 'BUSD',
      name: 'Binance USD',
      color: '#f0b90b',
      iconUrl: 'https://cdn.coinranking.com/6SJHRfClq/busd.svg',
      marketCap: '16428688944',
      price: '1.000100781180391',
      listedAt: 1563197940,
      tier: 1,
      change: '-0.04',
      rank: 7,
      sparkline: [
        '1.0002051928324327',
        '0.9999618848850473',
        '1.0000055899229026',
        '1.0003553021106633',
        '1.0008831958449138',
        '1.0007592094722946',
        '1.0009124324164107',
        '1.000400241652603',
        '1.0003230592996353',
        '1.0000730843551888',
        '0.9998014554913282',
        '0.9989542486188769',
        '1.0000282045384201',
        '1.0002977858610407',
        '1.000363767547623',
        '0.9998512516957475',
        '1.0001802697629298',
        '1.0004492321836715',
        '1.000417733236842',
        '1.0002062926070907',
        '1.000042056927549',
        '1.0010020621392428',
        '1.000326833572832',
        '1.00091018393021',
        '1.0003969891713966',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/vSo2fu9iE1s0Y+binanceusd-busd',
      '24hVolume': '4198471073',
      btcPrice: '0.000060104578078939',
    },
    {
      uuid: 'a91GCGd_u96cF',
      symbol: 'DOGE',
      name: 'Dogecoin',
      color: '#c2a633',
      iconUrl: 'https://cdn.coinranking.com/H1arXIuOZ/doge.svg',
      marketCap: '9572605662',
      price: '0.06995588508981651',
      listedAt: 1391212800,
      tier: 1,
      change: '-2.98',
      rank: 8,
      sparkline: [
        '0.0721047035527937',
        '0.07212813133327985',
        '0.07215814112604495',
        '0.07229016490767731',
        '0.071726217694418',
        '0.07160209013992848',
        '0.07153747168823406',
        '0.07150252857273251',
        '0.07150106330988103',
        '0.07163777041740861',
        '0.0715981252883927',
        '0.07194569394310996',
        '0.07182829049333034',
        '0.07177906802080179',
        '0.07166055023275437',
        '0.07174652159652896',
        '0.07180664162690761',
        '0.07158081465092475',
        '0.0714158938326',
        '0.0714529178434696',
        '0.07155443334708622',
        '0.07089280424062334',
        '0.07056109520142588',
        '0.06997546731044224',
        '0.06983893648642274',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/a91GCGd_u96cF+dogecoin-doge',
      '24hVolume': '344483548',
      btcPrice: '0.000004204245248663',
    },
    {
      uuid: 'qzawljRxB5bYu',
      symbol: 'ADA',
      name: 'Cardano',
      color: '#3cc8c8',
      iconUrl: 'https://cdn.coinranking.com/ryY28nXhW/ada.svg',
      marketCap: '8822547050',
      price: '0.25174894582511304',
      listedAt: 1506902400,
      tier: 1,
      change: '-1.29',
      rank: 9,
      sparkline: [
        '0.25494663975061616',
        '0.2545974190900606',
        '0.2547184270612001',
        '0.2552367629461972',
        '0.2541237862728743',
        '0.2538560104798458',
        '0.253347680352294',
        '0.2527571848168463',
        '0.2526909214230985',
        '0.2530715845428499',
        '0.2529305777507574',
        '0.2540032422403925',
        '0.2534350491845089',
        '0.2530198635774688',
        '0.2526359654156194',
        '0.25390585626351553',
        '0.25390048246393004',
        '0.2538779889184449',
        '0.2538714182982378',
        '0.25369919557437753',
        '0.25433701083617566',
        '0.2536406242037291',
        '0.2530146046247922',
        '0.25145437639770246',
        '0.2512896120102336',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/qzawljRxB5bYu+cardano-ada',
      '24hVolume': '227172657',
      btcPrice: '0.000015129739377643',
    },
    {
      uuid: 'PDKcptVnzJTmN',
      symbol: 'OKB',
      name: 'OKB',
      color: '#2d60e0',
      iconUrl: 'https://cdn.coinranking.com/xcZdYtX6E/okx.png',
      marketCap: '7199823699',
      price: '28.613011325261738',
      listedAt: 1538524800,
      tier: 1,
      change: '-4.04',
      rank: 10,
      sparkline: [
        '29.850500496903308',
        '29.679544260536538',
        '29.42066176012905',
        '29.406522823487645',
        '29.307891027878433',
        '29.59985975574527',
        '29.795100290308877',
        '29.550340314172427',
        '29.37976057635474',
        '29.39541238693346',
        '29.33459991624764',
        '29.41431432860815',
        '29.57269376486151',
        '29.901312867756857',
        '29.853770534529524',
        '29.990895726004663',
        '30.236712612363092',
        '30.009963221938854',
        '29.932098431151463',
        '29.8451167177844',
        '29.7196650429094',
        '29.237347799395305',
        '28.98784779059403',
        '29.102536069727616',
        '28.90339182438397',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/PDKcptVnzJTmN+okb-okb',
      '24hVolume': '34793051',
      btcPrice: '0.001719599669988222',
    },
    {
      uuid: 'uW2tk-ILY0ii',
      symbol: 'MATIC',
      name: 'Polygon',
      color: '#8247e5',
      iconUrl: 'https://cdn.coinranking.com/WulYRq14o/polygon.png',
      marketCap: '6933029989',
      price: '0.7737804514015402',
      listedAt: 1558961160,
      tier: 1,
      change: '-1.11',
      rank: 11,
      sparkline: [
        '0.7831477441754059',
        '0.7834744031514709',
        '0.7844810433413913',
        '0.785704449026017',
        '0.783079710458944',
        '0.7812012972813418',
        '0.7814605092813337',
        '0.7794560708070287',
        '0.7788287200959685',
        '0.78131191819674',
        '0.7806452184415443',
        '0.783891795638088',
        '0.7846206778473066',
        '0.7830817284298377',
        '0.7823327370918349',
        '0.7840022427801396',
        '0.7823365795715574',
        '0.781281311187267',
        '0.780808631283462',
        '0.782130244747581',
        '0.7824933548940998',
        '0.7798799433595898',
        '0.7789855328081505',
        '0.7762425152471116',
        '0.7732769115838494',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/uW2tk-ILY0ii+polygon-matic',
      '24hVolume': '183186320',
      btcPrice: '0.000046503060923849',
    },
    {
      uuid: 'VLqpJwogdhHNb',
      symbol: 'LINK',
      name: 'Chainlink',
      color: '#4680b0',
      iconUrl: 'https://cdn.coinranking.com/9NOP9tOem/chainlink.svg',
      marketCap: '5585369164',
      price: '5.585874825709948',
      listedAt: 1505952000,
      tier: 1,
      change: '-2.03',
      rank: 12,
      sparkline: [
        '5.708917098655775',
        '5.716108628553279',
        '5.722717705815166',
        '5.727113471639182',
        '5.697918037238452',
        '5.69632289031826',
        '5.680927916215718',
        '5.671424361820661',
        '5.670682206731776',
        '5.674547098512128',
        '5.663246123324437',
        '5.692078910607969',
        '5.676930940849397',
        '5.667715109775674',
        '5.65437232865521',
        '5.673235176858001',
        '5.66930375215043',
        '5.663790216419017',
        '5.662666265912064',
        '5.683824746219357',
        '5.682334613946945',
        '5.627717610980139',
        '5.62404289975778',
        '5.609856181373157',
        '5.589143322582505',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/VLqpJwogdhHNb+chainlink-link',
      '24hVolume': '206884494',
      btcPrice: '0.000335702817074899',
    },
    {
      uuid: 'D7B1x_ks7WhV5',
      symbol: 'LTC',
      name: 'Litecoin',
      color: '#345d9d',
      iconUrl: 'https://cdn.coinranking.com/BUvPxmc9o/ltcnew.svg',
      marketCap: '5311342897',
      price: '74.94983129225976',
      listedAt: 1382572800,
      tier: 1,
      change: '0.33',
      rank: 13,
      sparkline: [
        '74.61928566711298',
        '74.86414599670603',
        '75.1616224962408',
        '75.17103536788495',
        '75.04188516101462',
        '74.90720064288786',
        '74.95362574082105',
        '75.22039181445287',
        '75.27842143967538',
        '75.5626391332148',
        '75.37764675766779',
        '75.89384347431937',
        '75.99157238798826',
        '75.79337011146782',
        '75.55730736252383',
        '75.7240290044812',
        '75.67344102646395',
        '75.52319946255336',
        '75.25880359517535',
        '75.15069547510114',
        '75.2710780570237',
        '74.63845845527574',
        '74.63717668499187',
        '75.33781487521567',
        '75.34780358067512',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/D7B1x_ks7WhV5+litecoin-ltc',
      '24hVolume': '937614853',
      btcPrice: '0.004504374030777196',
    },
    {
      uuid: '25W7FG7om',
      symbol: 'DOT',
      name: 'Polkadot',
      color: '#d64cA8',
      iconUrl: 'https://cdn.coinranking.com/RsljYqnbu/polkadot.svg',
      marketCap: '5299507405',
      price: '4.4907696195681295',
      listedAt: 1598365200,
      tier: 1,
      change: '-0.49',
      rank: 14,
      sparkline: [
        '4.514915041434951',
        '4.513299113181956',
        '4.502183362449866',
        '4.502127613053879',
        '4.496486805131216',
        '4.49679237003875',
        '4.492583720177527',
        '4.489172336117008',
        '4.488485081717904',
        '4.48833185088323',
        '4.492908284596181',
        '4.516705641513182',
        '4.506344065186394',
        '4.506186319714622',
        '4.508570053490727',
        '4.528570502797591',
        '4.528638103693305',
        '4.524532177788501',
        '4.5240575713057884',
        '4.522077754553427',
        '4.524981449560995',
        '4.506019062655225',
        '4.509755868059436',
        '4.499917168488659',
        '4.489992537949832',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/25W7FG7om+polkadot-dot',
      '24hVolume': '157189331',
      btcPrice: '0.000269888613540814',
    },
    {
      uuid: 'MoTuySvg7',
      symbol: 'DAI',
      name: 'Dai',
      color: '#ea9999',
      iconUrl: 'https://cdn.coinranking.com/mAZ_7LwOE/mutli-collateral-dai.svg',
      marketCap: '5194079249',
      price: '0.9995680299288723',
      listedAt: 1585574040,
      tier: 1,
      change: '-0.06',
      rank: 15,
      sparkline: [
        '0.9997633180771012',
        '0.9996330252168509',
        '0.9996319102364645',
        '0.9997996797885074',
        '1.0004690045628288',
        '1.0003766816990756',
        '1.0004203912825516',
        '1.0001249705978732',
        '0.9999900763047699',
        '0.9997078465852843',
        '0.9997197907318033',
        '0.9989205732875006',
        '0.999657345147042',
        '0.9999650769701031',
        '1.0001925587817386',
        '0.9996324569692422',
        '0.9998104413877762',
        '1.000069141527042',
        '1.0000784826362712',
        '0.999844147681237',
        '0.9993810210979708',
        '1.0005466352454606',
        '0.999859879746985',
        '1.0003228366032328',
        '1.0000056868751106',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/MoTuySvg7+dai-dai',
      '24hVolume': '52330343',
      btcPrice: '0.000060072560516513',
    },
    {
      uuid: 'qUhEFk1I61atv',
      symbol: 'TRX',
      name: 'TRON',
      color: '#eb0029',
      iconUrl: 'https://cdn.coinranking.com/behejNqQs/trx.svg',
      marketCap: '5086807061',
      price: '0.055202308045343415',
      listedAt: 1505260800,
      tier: 1,
      change: '-0.11',
      rank: 16,
      sparkline: [
        '0.0552559415086344',
        '0.055297577065613356',
        '0.05530452392306441',
        '0.05535933469038551',
        '0.05521382689499253',
        '0.055204662910274524',
        '0.05515335152171117',
        '0.05518427167880142',
        '0.05530642882330366',
        '0.055273093744148995',
        '0.05530758455588483',
        '0.055403092415460535',
        '0.055431163696255316',
        '0.055433847693175484',
        '0.05541153968990347',
        '0.055459930623757515',
        '0.055372798780241116',
        '0.055354558950160566',
        '0.05537796894329937',
        '0.05535549062902626',
        '0.05538154428324343',
        '0.05533465381745265',
        '0.05542920874346655',
        '0.05538475375954354',
        '0.055263610311653735',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/qUhEFk1I61atv+tron-trx',
      '24hVolume': '197844477',
      btcPrice: '0.000003317577084714',
    },
    {
      uuid: 'Mtfb0obXVh59u',
      symbol: 'WETH',
      name: 'Wrapped Ether',
      color: '#303030',
      iconUrl: 'https://cdn.coinranking.com/KIviQyZlt/weth.svg',
      marketCap: '4922355958',
      price: '1207.1975526215315',
      listedAt: 1600259445,
      tier: 1,
      change: '-0.88',
      rank: 17,
      sparkline: [
        '1217.484200990433',
        '1216.8227816478534',
        '1217.0829733866922',
        '1216.878321921944',
        '1215.6490492094026',
        '1214.0834918311134',
        '1213.5035214304337',
        '1212.8123261958858',
        '1213.4565607414263',
        '1213.6025092571329',
        '1213.6891735523898',
        '1214.9317137554576',
        '1216.1317120302822',
        '1215.7573315006002',
        '1215.2156005229801',
        '1217.1747039128675',
        '1216.8056759938165',
        '1216.493574247634',
        '1216.4850815164932',
        '1216.6422814502832',
        '1216.01673702481',
        '1210.4556064849467',
        '1209.166824648914',
        '1208.7835808831774',
        '1207.8640672260108',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/Mtfb0obXVh59u+wrappedether-weth',
      '24hVolume': '140947116',
      btcPrice: '0.07255015478180966',
    },
    {
      uuid: 'xz24e0BjL',
      symbol: 'SHIB',
      name: 'Shiba Inu',
      color: '#fda32b',
      iconUrl: 'https://cdn.coinranking.com/fiZ4HfnRR/shib.png',
      marketCap: '4765607362',
      price: '0.000008083383284542',
      listedAt: 1620650373,
      tier: 1,
      change: '-1.31',
      rank: 18,
      sparkline: [
        '0.000008192406302161',
        '0.000008194303281896',
        '0.000008216095842052',
        '0.000008235361478578',
        '0.000008215342084758',
        '0.000008209502384004',
        '0.000008208020783861',
        '0.000008192305500659',
        '0.000008174936326059',
        '0.0000081860803859',
        '0.000008166321692483',
        '0.000008192402582668',
        '0.000008193266875393',
        '0.00000818676451728',
        '0.000008166469832905',
        '0.000008180363201767',
        '0.00000817685550996',
        '0.000008165254059629',
        '0.000008153244761159',
        '0.000008167500356662',
        '0.000008169885912707',
        '0.000008127239862802',
        '0.000008102551477092',
        '0.000008095931839671',
        '0.000008079323633761',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/xz24e0BjL+shibainu-shib',
      '24hVolume': '118428139',
      btcPrice: '4.85799382e-10',
    },
    {
      uuid: 'zNZHO_Sjf',
      symbol: 'SOL',
      name: 'Solana',
      color: '#000000',
      iconUrl: 'https://cdn.coinranking.com/yvUG4Qex5/solana.svg',
      marketCap: '4655574641',
      price: '12.81971641679681',
      listedAt: 1586539320,
      tier: 1,
      change: '14.93',
      rank: 19,
      sparkline: [
        '11.152116530232009',
        '11.265265944155466',
        '11.331357592802677',
        '11.32919218742093',
        '11.305472460074663',
        '11.284516811383037',
        '11.222240703378334',
        '11.118765150801897',
        '11.1511637497414',
        '11.243264434501455',
        '11.50890882965866',
        '11.825147395665878',
        '11.827998576351082',
        '11.8670249228162',
        '11.775020118596169',
        '11.820191132366075',
        '11.896479210242001',
        '11.894317830793241',
        '11.836727320600298',
        '11.936979841979909',
        '12.361260261300437',
        '12.77614618811778',
        '13.318062933360752',
        '13.078932904261702',
        '12.841278785285372',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/zNZHO_Sjf+solana-sol',
      '24hVolume': '1128523161',
      btcPrice: '0.000770445999865927',
    },
    {
      uuid: '_H5FVG9iW',
      symbol: 'UNI',
      name: 'Uniswap',
      color: '#ff007a',
      iconUrl:
        'https://cdn.coinranking.com/1heSvUgtl/uniswap-v2.svg?size=48x48',
      marketCap: '4203766830',
      price: '5.284056365949403',
      listedAt: 1600323371,
      tier: 1,
      change: '-2.38',
      rank: 20,
      sparkline: [
        '5.415564735148254',
        '5.428946886598403',
        '5.428183261062428',
        '5.407437618155074',
        '5.374579111390643',
        '5.393226015158106',
        '5.3870901191885565',
        '5.370560894136224',
        '5.363850928909494',
        '5.374047874242166',
        '5.370167757773919',
        '5.395805740528386',
        '5.384655116467561',
        '5.376530781851086',
        '5.365997894857968',
        '5.379819262091334',
        '5.396282189016169',
        '5.384671564856796',
        '5.379245388181507',
        '5.36651128940283',
        '5.3704887639499255',
        '5.321587309021795',
        '5.314647552010459',
        '5.298624074134143',
        '5.286388292019028',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/_H5FVG9iW+uniswap-uni',
      '24hVolume': '70662192',
      btcPrice: '0.000317563973948577',
    },
    {
      uuid: 'N2IgQ9Xme',
      symbol: 'BIT',
      name: 'BitDAO ',
      color: '#000000',
      iconUrl: 'https://cdn.coinranking.com/0ClFW1IpO/bitdao.png',
      marketCap: '3666581993',
      price: '0.38152868403131046',
      listedAt: 1639033619,
      tier: 1,
      change: '6.09',
      rank: 21,
      sparkline: [
        '0.3590283953429442',
        '0.36548554959960355',
        '0.36972638658649004',
        '0.3791746213829037',
        '0.3789775996466081',
        '0.37608983613228386',
        '0.37663581602494406',
        '0.3779272564606431',
        '0.3789654273498951',
        '0.37827031479476053',
        '0.3791578540149383',
        '0.3782374207877404',
        '0.3785342778922219',
        '0.377301774251071',
        '0.3768099178701689',
        '0.3775725613407192',
        '0.3780907088851613',
        '0.378326137383743',
        '0.37551327082725183',
        '0.37677180100318364',
        '0.3774179356176902',
        '0.3764757851381216',
        '0.37951730978983556',
        '0.3805391945555241',
        '0.3811726833808404',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/N2IgQ9Xme+bitdao-bit',
      '24hVolume': '6583308',
      btcPrice: '0.000022929309735814',
    },
    {
      uuid: 'dvUj0CzDZ',
      symbol: 'AVAX',
      name: 'Avalanche',
      color: '#e84242',
      iconUrl: 'https://cdn.coinranking.com/S0C6Cw2-w/avax-avalanche.png',
      marketCap: '3405001389',
      price: '11.322790180188045',
      listedAt: 1600961596,
      tier: 1,
      change: '1.10',
      rank: 22,
      sparkline: [
        '11.181052951490267',
        '11.180124054333806',
        '11.189655179086294',
        '11.205196320587648',
        '11.166356956432185',
        '11.160486504411072',
        '11.146145605949059',
        '11.106606506187653',
        '11.112786397193508',
        '11.162293898967455',
        '11.212624322885455',
        '11.298211638898701',
        '11.26178680298843',
        '11.254882801519457',
        '11.221606040537266',
        '11.292127838618335',
        '11.323390391654785',
        '11.311488104764548',
        '11.332402928449213',
        '11.345983088950517',
        '11.388920706725335',
        '11.334209428390281',
        '11.442569958086644',
        '11.400107151270605',
        '11.351553463916703',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/dvUj0CzDZ+avalanche-avax',
      '24hVolume': '221085844',
      btcPrice: '0.000680482946581965',
    },
    {
      uuid: 'Knsels4_Ol-Ny',
      symbol: 'ATOM',
      name: 'Cosmos',
      color: '#5064fb',
      iconUrl: 'https://cdn.coinranking.com/HJzHboruM/atom.svg',
      marketCap: '3255367887',
      price: '10.145702613745385',
      listedAt: 1552520100,
      tier: 1,
      change: '2.22',
      rank: 23,
      sparkline: [
        '9.913522631726563',
        '9.864687962197557',
        '9.854793021770726',
        '9.868265358252664',
        '9.821306799394055',
        '9.822360987281721',
        '9.819716108606992',
        '9.863275247091085',
        '9.876912504581934',
        '9.90607246954423',
        '9.914819149192642',
        '9.957161703230707',
        '9.903405589784843',
        '9.881822576657486',
        '9.946202719867042',
        '10.194147314875822',
        '10.144289639344757',
        '10.131329585063316',
        '10.135154173503123',
        '10.157635756655816',
        '10.193076865058757',
        '10.129175663570914',
        '10.156684300123937',
        '10.146073237551436',
        '10.17288756322463',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/Knsels4_Ol-Ny+cosmos-atom',
      '24hVolume': '236267827',
      btcPrice: '0.000609741724422835',
    },
    {
      uuid: '67YlI0K1b',
      symbol: 'TON',
      name: 'Toncoin',
      color: '#0088cc',
      iconUrl: 'https://cdn.coinranking.com/1mf2KPPah/toncoin.png',
      marketCap: '3145512014',
      price: '2.1345889988178497',
      listedAt: 1640268459,
      tier: 1,
      change: '-0.13',
      rank: 24,
      sparkline: [
        '2.1809980399445146',
        '2.1924902190904407',
        '2.2083929388145784',
        '2.250370214115471',
        '2.253345083494107',
        '2.2367188522318546',
        '2.2363502076736474',
        '2.2300408495016693',
        '2.2311222103778356',
        '2.233990038236479',
        '2.2206975845956554',
        '2.21373338519387',
        '2.202689135777943',
        '2.188924819492869',
        '2.166602119044608',
        '2.1902980157998',
        '2.1740965412717204',
        '2.165551142681451',
        '2.1757289178970662',
        '2.163182436865931',
        '2.155082970561724',
        '2.142114461027879',
        '2.14641312471729',
        '2.139792724250399',
        '2.144539682431999',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/67YlI0K1b+toncoin-ton',
      '24hVolume': '61461298',
      btcPrice: '0.000128285642367427',
    },
    {
      uuid: 'x4WXHge-vvFY',
      symbol: 'WBTC',
      name: 'Wrapped BTC',
      color: '#000000',
      iconUrl: 'https://cdn.coinranking.com/o3-8cvCHu/wbtc[1].svg',
      marketCap: '3141976826',
      price: '16599.834955786428',
      listedAt: 1549894980,
      tier: 1,
      change: '-0.62',
      rank: 25,
      sparkline: [
        '16705.831733186576',
        '16701.847514282184',
        '16704.864501777844',
        '16708.770390121746',
        '16692.370999995812',
        '16670.71664894215',
        '16663.512414207366',
        '16656.38292139363',
        '16657.175998895153',
        '16662.45518823707',
        '16663.2747249076',
        '16695.01502245144',
        '16696.192213429134',
        '16700.835308897193',
        '16703.374193553234',
        '16714.178143729896',
        '16715.68046477508',
        '16710.620282343934',
        '16694.86588934822',
        '16696.367548721086',
        '16696.878997778294',
        '16635.605531101635',
        '16626.62498283662',
        '16610.91772317209',
        '16608.924534880676',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/x4WXHge-vvFY+wrappedbtc-wbtc',
      '24hVolume': '30097133',
      btcPrice: '0.997625534318631',
    },
    {
      uuid: '3mVx2FX_iJFp5',
      symbol: 'XMR',
      name: 'Monero',
      color: '#ff7519',
      iconUrl: 'https://cdn.coinranking.com/Syz-oSd_Z/xmr.svg',
      marketCap: '2690212315',
      price: '147.758621653562',
      listedAt: 1422489600,
      tier: 1,
      change: '-0.79',
      rank: 26,
      sparkline: [
        '148.88412472372545',
        '148.73488217326766',
        '148.6870005406338',
        '148.56218588607044',
        '148.24359304525075',
        '148.09245441394438',
        '148.13883508878246',
        '147.8749134306907',
        '147.89923427779271',
        '148.26601335076055',
        '148.42978141610496',
        '148.59784021404812',
        '148.52449541765813',
        '148.29621539532224',
        '148.47394920789122',
        '148.75745521072562',
        '148.50267956178587',
        '148.48471038881064',
        '148.6157366762578',
        '148.49918653976982',
        '148.5490715678019',
        '148.2846287156359',
        '148.42525071841723',
        '148.43598006778575',
        '147.94955157028608',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/3mVx2FX_iJFp5+monero-xmr',
      '24hVolume': '63710701',
      btcPrice: '0.008880074667605977',
    },
    {
      uuid: 'DXwP4wF9ksbBO',
      symbol: 'HT',
      name: 'Huobi Token',
      color: '#2daadf',
      iconUrl: 'https://cdn.coinranking.com/ryFpQe0c7/ht.svg',
      marketCap: '2619835684',
      price: '5.239671380261861',
      listedAt: 1517702400,
      tier: 1,
      change: '1.17',
      rank: 27,
      sparkline: [
        '5.176897445556841',
        '5.167887718756699',
        '5.181102142027745',
        '5.192905350716477',
        '5.186549610173648',
        '5.164067346518502',
        '5.1512841494894195',
        '5.155254124407819',
        '5.162555613474131',
        '5.153018290713841',
        '5.144390962865089',
        '5.153046795843879',
        '5.156287097558776',
        '5.159762598663896',
        '5.152895328952063',
        '5.153138845040672',
        '5.154688159120569',
        '5.158275979973123',
        '5.159195261345905',
        '5.197190281054985',
        '5.305180389821452',
        '5.267297532979783',
        '5.260515565617349',
        '5.263605516807983',
        '5.246948388542317',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/DXwP4wF9ksbBO+huobitoken-ht',
      '24hVolume': '10166191',
      btcPrice: '0.000314896501941765',
    },
    {
      uuid: 'hnfQfsYfeIGUQ',
      symbol: 'ETC',
      name: 'Ethereum Classic',
      color: '#699070',
      iconUrl: 'https://cdn.coinranking.com/rJfyor__W/etc.svg',
      marketCap: '2167749999',
      price: '15.687116043088709',
      listedAt: 1469577600,
      tier: 1,
      change: '-2.88',
      rank: 28,
      sparkline: [
        '16.194011159717483',
        '16.20350531883972',
        '16.175349553099707',
        '16.163760618630786',
        '16.13749483192551',
        '16.111668916574992',
        '16.099150248874437',
        '16.06545736981835',
        '16.049983571233252',
        '16.069392956131193',
        '16.066544733173306',
        '16.133268821394257',
        '16.08612739587469',
        '16.067637637110145',
        '16.034827192193386',
        '16.085321201813837',
        '16.067951894759652',
        '16.03273682456025',
        '16.0005859266631',
        '15.995338210737343',
        '16.047219952120937',
        '15.90085403202982',
        '15.858455496895905',
        '15.77651770984341',
        '15.716660884855845',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/hnfQfsYfeIGUQ+ethereumclassic-etc',
      '24hVolume': '182202407',
      btcPrice: '0.000942772477322099',
    },
    {
      uuid: 'ZlZpzOJo43mIo',
      symbol: 'BCH',
      name: 'Bitcoin Cash',
      color: '#8dc451',
      iconUrl: 'https://cdn.coinranking.com/By8ziihX7/bch.svg',
      marketCap: '1899906151',
      price: '99.30826607124312',
      listedAt: 1541808000,
      tier: 1,
      change: '-0.59',
      rank: 29,
      sparkline: [
        '99.82985410954446',
        '99.73271983215189',
        '99.67593798076805',
        '99.69607659136803',
        '99.47547496322522',
        '99.38947160751924',
        '99.24869010697965',
        '99.10078201492539',
        '99.01628151536607',
        '99.0912050351333',
        '99.08756559672072',
        '99.54224144999918',
        '99.74980910113175',
        '99.6921238450883',
        '99.87004919187122',
        '100.1902237100426',
        '100.14706300482015',
        '99.88404298066119',
        '99.73606221492915',
        '99.6219258189013',
        '99.81692988243577',
        '99.30105813769833',
        '99.56431847540551',
        '99.88685470010617',
        '99.3853720696688',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/ZlZpzOJo43mIo+bitcoincash-bch',
      '24hVolume': '149985409',
      btcPrice: '0.005968279941665666',
    },
    {
      uuid: 'Hm3OlynlC',
      symbol: 'TWT',
      name: 'Trust Wallet Token',
      color: '#3375bb',
      iconUrl: 'https://cdn.coinranking.com/cZ5j4jiOk/trust-wallet-token.svg',
      marketCap: '1746583685',
      price: '1.4387037611432358',
      listedAt: 1610717385,
      tier: 1,
      change: '-1.94',
      rank: 30,
      sparkline: [
        '1.475728069889221',
        '1.4748177467445112',
        '1.4794211233649441',
        '1.4922046623197334',
        '1.4910337488590033',
        '1.4840591091022977',
        '1.4894390891305236',
        '1.492766234901235',
        '1.5002267452704507',
        '1.4986706703582047',
        '1.4918073109369627',
        '1.492149038507516',
        '1.4815137557368152',
        '1.472871409750683',
        '1.463690192169683',
        '1.4734066248042383',
        '1.4724325470129631',
        '1.4654303217737525',
        '1.462838469680197',
        '1.464271207632552',
        '1.4665918488024716',
        '1.4511721219202285',
        '1.4418759785853494',
        '1.4386816257057822',
        '1.4417109685126022',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/Hm3OlynlC+trustwallettoken-twt',
      '24hVolume': '34583468',
      btcPrice: '0.00008646396860328',
    },
    {
      uuid: 'f3iaFeCKEmkaZ',
      symbol: 'XLM',
      name: 'Stellar',
      color: '#000000',
      iconUrl:
        'https://cdn.coinranking.com/78CxK1xsp/Stellar_symbol_black_RGB.svg',
      marketCap: '1485333335',
      price: '0.07351125199438971',
      listedAt: 1484611200,
      tier: 1,
      change: '-0.33',
      rank: 31,
      sparkline: [
        '0.07363021248132329',
        '0.07395006883631484',
        '0.07404716819458775',
        '0.07415661018432201',
        '0.07390369321364967',
        '0.07372625937249508',
        '0.07373641968614938',
        '0.07379062695129647',
        '0.07372282611856337',
        '0.07380205956449411',
        '0.0738212181016919',
        '0.0740833774720904',
        '0.07394326763967164',
        '0.0740316709091869',
        '0.07387574396356662',
        '0.0740423957161395',
        '0.07401044781182509',
        '0.07388908615067331',
        '0.0739691620177',
        '0.07395826848559692',
        '0.07415838918754668',
        '0.07355339501188127',
        '0.07336537542640985',
        '0.073204366835178',
        '0.07335818414362852',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/f3iaFeCKEmkaZ+stellar-xlm',
      '24hVolume': '52231618',
      btcPrice: '0.000004417917542233',
    },
    {
      uuid: 'Pe93bIOD2',
      symbol: 'LDO',
      name: 'Lido DAO Token',
      color: '#77cced',
      iconUrl: 'https://cdn.coinranking.com/Wp6LFY6ZZ/8000.png',
      marketCap: '1417232123',
      price: '1.2135366768807812',
      listedAt: 1627361901,
      tier: 1,
      change: '-2.09',
      rank: 32,
      sparkline: [
        '1.2369088928322873',
        '1.239221626709207',
        '1.2466014714237872',
        '1.2288628696682398',
        '1.2224680081408463',
        '1.2122062570147858',
        '1.2175293536943756',
        '1.2147982985416443',
        '1.218069053503655',
        '1.2152503909252226',
        '1.237828925449057',
        '1.234598148458489',
        '1.236461643826276',
        '1.2312689656686056',
        '1.229438328392486',
        '1.233709767052457',
        '1.2364833202314345',
        '1.2376127846954423',
        '1.2393051490290687',
        '1.249149709389757',
        '1.2533674944060005',
        '1.2462396975173158',
        '1.2208571312319687',
        '1.2215030743116393',
        '1.2201427545434653',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/Pe93bIOD2+lidodaotoken-ldo',
      '24hVolume': '97291545',
      btcPrice: '0.000072931759798397',
    },
    {
      uuid: 'KfWtaeV1W',
      symbol: 'FRAX',
      name: 'Frax',
      color: '#000000',
      iconUrl: 'https://cdn.coinranking.com/BpVNCX-NM/frax.png',
      marketCap: '1384107859',
      price: '0.9999377064620036',
      listedAt: 1615299931,
      tier: 1,
      change: '0.05',
      rank: 33,
      sparkline: [
        '0.9994083219632349',
        '0.9994639175053248',
        '0.9992752702613692',
        '0.9993506019911749',
        '1.0001225985323874',
        '1.0000571348559153',
        '0.9999398270332687',
        '0.9995558387536267',
        '0.9993590735077936',
        '0.999240151459573',
        '0.9992110283284411',
        '0.9983974580316796',
        '0.9992583638778287',
        '0.999390694026502',
        '0.9995609295409974',
        '0.999055829189488',
        '0.9993351604009919',
        '0.9995615174732684',
        '0.999691793161828',
        '0.9994812495959683',
        '0.9992265134144797',
        '1.0005168496965473',
        '0.9996792200854123',
        '1.0001337675198017',
        '0.9999078860464851',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/KfWtaeV1W+frax-frax',
      '24hVolume': '5589041',
      btcPrice: '0.000060094777529506',
    },
    {
      uuid: 'TpHE2IShQw-sJ',
      symbol: 'ALGO',
      name: 'Algorand',
      color: null,
      iconUrl: 'https://cdn.coinranking.com/lzbmCkUGB/algo.svg',
      marketCap: '1295387006',
      price: '0.18148640009341455',
      listedAt: 1562082540,
      tier: 1,
      change: '-0.41',
      rank: 34,
      sparkline: [
        '0.18211752776254128',
        '0.1820335111167289',
        '0.18145295662049596',
        '0.181132450509706',
        '0.18086767118855604',
        '0.1813309966564416',
        '0.18110119299332025',
        '0.1803094535795267',
        '0.18045697664394442',
        '0.1808266101266263',
        '0.18110908371938658',
        '0.18263731897119506',
        '0.18241998874683582',
        '0.18215028696967994',
        '0.18304718105177806',
        '0.18446088683074374',
        '0.184695753152109',
        '0.18449844660666898',
        '0.184001573133215',
        '0.18365166775710728',
        '0.18357852481028897',
        '0.18172418494714568',
        '0.1819965960911986',
        '0.18218202486384902',
        '0.18157149820211874',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/TpHE2IShQw-sJ+algorand-algo',
      '24hVolume': '60958894',
      btcPrice: '0.000010907064277868',
    },
    {
      uuid: 'CiixT63n3',
      symbol: 'WSTETH',
      name: 'Wrapped liquid staked Ether 2.0',
      color: '#70b9dc',
      iconUrl: 'https://cdn.coinranking.com/LQg69oxqx/wstETH.PNG',
      marketCap: '1274775297',
      price: '1316.8362788646236',
      listedAt: 1638756833,
      tier: 1,
      change: '-0.85',
      rank: 35,
      sparkline: [
        '1327.125355977236',
        '1326.3160925073785',
        '1327.0433285395113',
        '1325.8131779789921',
        '1324.1538330035678',
        '1322.3791160064834',
        '1322.1507866814395',
        '1321.2527958429973',
        '1321.9836106905632',
        '1322.1271084066875',
        '1322.093733373645',
        '1323.6028213049717',
        '1324.7525334688128',
        '1324.3485277002774',
        '1323.8339941184984',
        '1326.2462185288337',
        '1325.7503865027506',
        '1325.4714569795551',
        '1326.054335996086',
        '1326.4613176919916',
        '1325.9975450168022',
        '1319.99064429401',
        '1318.1385739837415',
        '1318.1234428826074',
        '1317.105403334846',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/CiixT63n3+wrappedliquidstakedether20-wsteth',
      '24hVolume': '5091746',
      btcPrice: '0.07913922261229303',
    },
    {
      uuid: 'SbWqqTui-',
      symbol: 'ENS',
      name: 'EnergySwap',
      color: '#ffda55',
      iconUrl:
        'https://cdn.coinranking.com/fmYxEUV5a/cropped-logo37-Converted-01-192x192.png',
      marketCap: '1108760783',
      price: '11.08760783365401',
      listedAt: 1626134763,
      tier: 1,
      change: '-1.59',
      rank: 36,
      sparkline: [
        '11.32756376293138',
        '11.320097631456667',
        '11.325591703731666',
        '11.351006067286306',
        '11.294151813529067',
        '11.287827486456623',
        '11.273326427482864',
        '11.266831546649296',
        '11.280324117938262',
        '11.297472657121586',
        '11.274382666950558',
        '11.311445361862813',
        '11.306795962790543',
        '11.35004656127283',
        '11.310409769758659',
        '11.378293738421629',
        '11.3615803419121',
        '11.306133685485122',
        '11.281808329305697',
        '11.245355835897294',
        '11.284640690862844',
        '11.168935895197212',
        '11.172892526614877',
        '11.11515448271392',
        '11.090833008781964',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/SbWqqTui-+energyswap-ens',
      '24hVolume': '3065062',
      btcPrice: '0.000666326394227277',
    },
    {
      uuid: 'ncYFcP709',
      symbol: 'CAKE',
      name: 'PancakeSwap',
      color: '#fe9555',
      iconUrl:
        'https://cdn.coinranking.com/aRtgdw7bQ/pancakeswap-cake-logo.png',
      marketCap: '1104329614',
      price: '3.1763672182012197',
      listedAt: 1613642379,
      tier: 1,
      change: '-0.58',
      rank: 37,
      sparkline: [
        '3.1905155164094214',
        '3.1901617973455356',
        '3.1915315425216026',
        '3.199728628532568',
        '3.1998450207634788',
        '3.193610566812571',
        '3.1906412173317005',
        '3.186794754327428',
        '3.1871368358886767',
        '3.1924355105086977',
        '3.194361001034081',
        '3.201371871733295',
        '3.2002549093866213',
        '3.1966951332300466',
        '3.195253247733881',
        '3.1949835452923576',
        '3.194608265326613',
        '3.195460258386079',
        '3.1941658970884994',
        '3.192819483099079',
        '3.1915137376902742',
        '3.1833481362776967',
        '3.1840878857941757',
        '3.179372263025182',
        '3.179344754817548',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/ncYFcP709+pancakeswap-cake',
      '24hVolume': '15339566',
      btcPrice: '0.000190894972853063',
    },
    {
      uuid: 'aMNLwaUbY',
      symbol: 'ICP',
      name: 'Internet Computer (DFINITY)',
      color: '#00042b',
      iconUrl: 'https://cdn.coinranking.com/1uJ_RVrmC/dfinity-icp.png',
      marketCap: '1076390873',
      price: '3.8553224751791464',
      listedAt: 1601555742,
      tier: 1,
      change: '-6.88',
      rank: 38,
      sparkline: [
        '4.137335379115295',
        '4.142489052487213',
        '4.163536440846623',
        '4.141050794710561',
        '4.003716235191856',
        '4.0197582694177205',
        '4.030539064857994',
        '4.009734548516746',
        '4.016724832403361',
        '4.0304984963331325',
        '4.013869022904974',
        '4.021020413207414',
        '4.010925135954191',
        '4.010294220713206',
        '4.009790794490574',
        '4.023622983281953',
        '4.016656552007699',
        '4.004377600756483',
        '4.006399284967734',
        '3.97483779575651',
        '3.93578900237174',
        '3.892185143294113',
        '3.880520174836364',
        '3.8658412678083898',
        '3.853588466226256',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/aMNLwaUbY+internetcomputerdfinity-icp',
      '24hVolume': '52154751',
      btcPrice: '0.000231699179812057',
    },
    {
      uuid: 'FEbS54wxo4oIl',
      symbol: 'VET',
      name: 'VeChain',
      color: '#4bc0fa',
      iconUrl: 'https://cdn.coinranking.com/B1_TDu9Dm/VEN.svg',
      marketCap: '1059482355',
      price: '0.015869841089801568',
      listedAt: 1533427200,
      tier: 1,
      change: '-1.95',
      rank: 39,
      sparkline: [
        '0.0161991234860722',
        '0.016229449599791525',
        '0.016253783860233965',
        '0.016277814041856534',
        '0.016206525387313497',
        '0.016189078094808965',
        '0.016193793790670447',
        '0.01615244035244005',
        '0.016134135076105',
        '0.016125446344298577',
        '0.016076219114040304',
        '0.016113747276883375',
        '0.016085181009561706',
        '0.016078085809331625',
        '0.016072253089927754',
        '0.01612946596335501',
        '0.01614530394626752',
        '0.016096969808049515',
        '0.016131382968653202',
        '0.016155156239767543',
        '0.016197934169455322',
        '0.016051797611998143',
        '0.01596951405045996',
        '0.015932543045578708',
        '0.015882697952251393',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/FEbS54wxo4oIl+vechain-vet',
      '24hVolume': '41794848',
      btcPrice: '9.5375398243e-7',
    },
    {
      uuid: 'bauj_21eYVwso',
      symbol: 'QNT',
      name: 'Quant',
      color: '#585e63',
      iconUrl: 'https://cdn.coinranking.com/a-i9Dl392/quant.png',
      marketCap: '1036581857',
      price: '106.01992806361096',
      listedAt: 1533945600,
      tier: 1,
      change: '-2.74',
      rank: 40,
      sparkline: [
        '109.21703361163937',
        '109.20823845995241',
        '108.92190350153572',
        '108.99263745162034',
        '108.9095107365518',
        '108.97596601705956',
        '108.83861557686738',
        '108.53542894391727',
        '108.38936620429577',
        '108.7066460218435',
        '108.96402311643169',
        '109.32423204298273',
        '109.13758640355375',
        '109.11631658251198',
        '109.10236253541493',
        '109.06491802338266',
        '108.98629219251684',
        '108.47732397029436',
        '107.64685214772054',
        '107.85571287718763',
        '108.13424846887312',
        '106.96552763629526',
        '106.42199537942302',
        '106.41906392152616',
        '106.05563180807816',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/bauj_21eYVwso+quant-qnt',
      '24hVolume': '24588374',
      btcPrice: '0.006371640902731607',
    },
    {
      uuid: 'JCKLgWPAF',
      symbol: 'USDP',
      name: 'Pax Dollar',
      color: '#2ac27a',
      iconUrl: 'https://cdn.coinranking.com/KUd2bJXdn/3330.png',
      marketCap: '1013908482',
      price: '0.9979927752966625',
      listedAt: 1650960626,
      tier: 1,
      change: '0.20',
      rank: 41,
      sparkline: [
        '0.9961582808986725',
        '0.9953516408067198',
        '0.997755416225069',
        '0.9958668986420413',
        '0.9963734368420699',
        '0.9959625189917909',
        '0.9963360121169921',
        '0.9959970929350634',
        '0.9955928618130155',
        '0.9972995380143994',
        '0.9963915590369031',
        '0.9955246458455339',
        '0.9965611548272384',
        '0.9974235543205962',
        '0.9974195430729712',
        '0.9970063360662725',
        '0.997329545432109',
        '0.9976449844083538',
        '0.9974793771724759',
        '0.9971172383455387',
        '0.9969190776119993',
        '0.997809725851308',
        '0.9975288377912576',
        '0.9983195937726667',
        '0.9981513373293499',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/JCKLgWPAF+paxdollar-usdp',
      '24hVolume': '1921800',
      btcPrice: '0.00005997789004248',
    },
    {
      uuid: 'AaQUAs2Mc',
      symbol: 'LUNC',
      name: 'Terra Classic',
      color: '#0E3CA5',
      iconUrl: 'https://cdn.coinranking.com/F-PJdF8Um/LUNA.svg',
      marketCap: '1012237907',
      price: '0.000153394535260407',
      listedAt: 1565957940,
      tier: 1,
      change: '-0.54',
      rank: 42,
      sparkline: [
        '0.000154717704972531',
        '0.000155370536679713',
        '0.000157560084196955',
        '0.000157837616511397',
        '0.000159863649194465',
        '0.000159535096036187',
        '0.000160651236705864',
        '0.000163209949357773',
        '0.000163071260200597',
        '0.000163250657468299',
        '0.000165068683111007',
        '0.000166690834587394',
        '0.000165676146584759',
        '0.000161584438683583',
        '0.000158934581761884',
        '0.000159882185162098',
        '0.000158456806344565',
        '0.000157738129631476',
        '0.000157424180917045',
        '0.000155948766519752',
        '0.000156945379838392',
        '0.000155517142271104',
        '0.000152767095229309',
        '0.000151636478704597',
        '0.000152107836989119',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/AaQUAs2Mc+terraclassic-lunc',
      '24hVolume': '172387767',
      btcPrice: '9.218784741e-9',
    },
    {
      uuid: 'ymQub4fuB',
      symbol: 'FIL',
      name: 'Filecoin',
      color: '#0090ff',
      iconUrl:
        'https://cdn.coinranking.com/vUmvv-IQA/FIL3-filecoin.svg?size=48x48',
      marketCap: '994517674',
      price: '3.037881270388632',
      listedAt: 1602839473,
      tier: 1,
      change: '-3.75',
      rank: 43,
      sparkline: [
        '3.1586232907608696',
        '3.159649166828764',
        '3.1654533032726615',
        '3.1645465180516563',
        '3.154810499116833',
        '3.1529425821425696',
        '3.1493554688161645',
        '3.1579442874703108',
        '3.15810973213943',
        '3.150845341516741',
        '3.145967345828478',
        '3.171544685718707',
        '3.163338229063844',
        '3.1573422379206524',
        '3.1486572064543306',
        '3.1645860347671873',
        '3.1756157180864184',
        '3.1741313916434444',
        '3.1612124459634936',
        '3.1502615641447713',
        '3.1578976981015643',
        '3.13052431495242',
        '3.1201772414706515',
        '3.081333355967463',
        '3.066383545035502',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/ymQub4fuB+filecoin-fil',
      '24hVolume': '122977284',
      btcPrice: '0.000182572172171602',
    },
    {
      uuid: 'jad286TjB',
      symbol: 'HBAR',
      name: 'Hedera',
      color: '#000000',
      iconUrl: 'https://cdn.coinranking.com/dSCnSLilQ/hedera.svg',
      marketCap: '948913070',
      price: '0.03835817349763962',
      listedAt: 1568704980,
      tier: 1,
      change: '-2.73',
      rank: 44,
      sparkline: [
        '0.03941191335580761',
        '0.03926708162385771',
        '0.03905519718482213',
        '0.03908148043901763',
        '0.03900692796238817',
        '0.039068823539975456',
        '0.039108101574654656',
        '0.038852718466030944',
        '0.038894028557462504',
        '0.039074975781288226',
        '0.03908230523674179',
        '0.039108610934218406',
        '0.03908634974075703',
        '0.03912266673611478',
        '0.039111882061402266',
        '0.03914823246323522',
        '0.039182052620606866',
        '0.0391503584768796',
        '0.039045525815710996',
        '0.03908877196029636',
        '0.039061214469928',
        '0.03859174199804113',
        '0.03863494863916305',
        '0.03856869026083063',
        '0.038511828516282716',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/jad286TjB+hedera-hbar',
      '24hVolume': '16672278',
      btcPrice: '0.000002305269506172',
    },
    {
      uuid: '65PHZTpmE55b',
      symbol: 'CRO',
      name: 'Cronos',
      color: '#01275d',
      iconUrl: 'https://cdn.coinranking.com/2o91jm73M/cro.svg',
      marketCap: '917627621',
      price: '0.05696405658521507',
      listedAt: 1548953220,
      tier: 1,
      change: '-0.45',
      rank: 45,
      sparkline: [
        '0.0572746417663511',
        '0.057310547551717154',
        '0.057331956403291',
        '0.057354587598171906',
        '0.05735548372345932',
        '0.05736728372512814',
        '0.05739974108798606',
        '0.05748491035850372',
        '0.05762782251797978',
        '0.05775579692275304',
        '0.057811327138410466',
        '0.057944279136108945',
        '0.057999850886821525',
        '0.05789787094973912',
        '0.05770709933695707',
        '0.0576775029427914',
        '0.05762640203697751',
        '0.057562856989271355',
        '0.05746350351126627',
        '0.05745070072711954',
        '0.0574663226503998',
        '0.057185078032488595',
        '0.05714619949430355',
        '0.05706454574308542',
        '0.056993380342584776',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/65PHZTpmE55b+cronos-cro',
      '24hVolume': '12736093',
      btcPrice: '0.000003423455566826',
    },
    {
      uuid: 'iAzbfXiBBKkR6',
      symbol: 'EOS',
      name: 'EOS',
      color: '#443f54',
      iconUrl: 'https://cdn.coinranking.com/PqOYrWSje/eos2.svg',
      marketCap: '862365296',
      price: '0.8723845153548698',
      listedAt: 1498694400,
      tier: 1,
      change: '-1.56',
      rank: 46,
      sparkline: [
        '0.8865458215379107',
        '0.8867012051880654',
        '0.8880274669421268',
        '0.8869962706014419',
        '0.8838999674020205',
        '0.8831129445729744',
        '0.8826244730094654',
        '0.882278179691943',
        '0.8804535079192044',
        '0.8808072907083727',
        '0.8812805172574362',
        '0.8843899110172188',
        '0.8834718664522698',
        '0.8817187369637438',
        '0.8814212223157388',
        '0.883182393353628',
        '0.8826348216039503',
        '0.8817037754252093',
        '0.8805987850449386',
        '0.8801151375731893',
        '0.8805754696008081',
        '0.873344489117769',
        '0.8726727540205363',
        '0.8729837646326352',
        '0.8723794338468155',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/iAzbfXiBBKkR6+eos-eos',
      '24hVolume': '99572394',
      btcPrice: '0.000052429019359546',
    },
    {
      uuid: 'pxtKbG5rg',
      symbol: 'SAND',
      name: 'The Sandbox',
      color: '#00adef',
      iconUrl: 'https://cdn.coinranking.com/kd_vwOcnI/sandbox.png',
      marketCap: '851632348',
      price: '0.4057999509758484',
      listedAt: 1613583024,
      tier: 1,
      change: '-1.25',
      rank: 47,
      sparkline: [
        '0.4108829993271913',
        '0.41058042253835586',
        '0.4100955102927784',
        '0.409488171296883',
        '0.40988025032513126',
        '0.4100613279236907',
        '0.41020308946282974',
        '0.40739094602286974',
        '0.406666041680603',
        '0.4066390315057636',
        '0.40500286883324627',
        '0.4093140893899453',
        '0.40927244321677086',
        '0.4079461072034598',
        '0.4100624503279724',
        '0.41119702094005234',
        '0.4092687173973359',
        '0.4071081734508539',
        '0.40602624532300036',
        '0.40634769578293045',
        '0.40738067019564644',
        '0.4043896919612177',
        '0.4062828272532953',
        '0.4042589519964599',
        '0.4048873098044334',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/pxtKbG5rg+thesandbox-sand',
      '24hVolume': '40419205',
      btcPrice: '0.000024387976988749',
    },
    {
      uuid: 'B8xT718SbVhhh',
      symbol: 'PAX',
      name: 'Paxos Standard',
      color: '#085229',
      iconUrl: 'https://cdn.coinranking.com/H1NkFcuhX/pax.svg',
      marketCap: '848075535',
      price: '0.9945003557901642',
      listedAt: 1539302400,
      tier: 1,
      change: '-0.20',
      rank: 48,
      sparkline: [
        '0.9963515097322179',
        '0.9964774375104708',
        '0.9969237818179378',
        '0.9964237560698215',
        '0.9965256399604144',
        '0.996315218273395',
        '0.9962559005809585',
        '0.9966440443324747',
        '0.9960709259124941',
        '1.0001433572566396',
        '1.000614947400247',
        '0.9998094990685159',
        '1.0007565890527335',
        '0.9968444160330838',
        '0.9961489052282018',
        '0.9960027207166664',
        '0.9977805000690053',
        '0.9970512908615662',
        '0.9961661519915578',
        '0.9965306045120347',
        '0.9958982813888422',
        '0.9960207604398088',
        '0.9949472224134732',
        '0.9952275972520267',
        '0.9955400378494549',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/B8xT718SbVhhh+paxosstandard-pax',
      '24hVolume': '857479',
      btcPrice: '0.000059768000794453',
    },
    {
      uuid: '3nNpuxHJ8',
      symbol: 'FXS',
      name: 'Frax Share',
      color: '#000000',
      iconUrl: 'https://cdn.coinranking.com/_UuWZ5htj/FS.png',
      marketCap: '842080936',
      price: '4.77226142385446',
      listedAt: 1640985232,
      tier: 1,
      change: '3.49',
      rank: 49,
      sparkline: [
        '4.615553251596329',
        '4.623283704671132',
        '4.6462512017910935',
        '4.6529805930687',
        '4.650854016513599',
        '4.642426433046301',
        '4.622719964549305',
        '4.613674829921087',
        '4.607136850075915',
        '4.539172944457445',
        '4.541362800813135',
        '4.557223540005609',
        '4.562712740511474',
        '4.624613005316253',
        '4.623712100738557',
        '4.621452811537592',
        '4.6291391765214644',
        '4.637833830936924',
        '4.633401319561276',
        '4.646042761294448',
        '4.647162766694156',
        '4.706124678462554',
        '4.749155372182352',
        '4.75573319911177',
        '4.765017748940023',
      ],
      lowVolume: false,
      coinrankingUrl: 'https://coinranking.com/coin/3nNpuxHJ8+fraxshare-fxs',
      '24hVolume': '6277312',
      btcPrice: '0.000286805854730587',
    },
    {
      uuid: 'VcMY11NONHSA0',
      symbol: 'BSV',
      name: 'Bitcoin SV',
      color: '#eab300',
      iconUrl: 'https://cdn.coinranking.com/388ehh6kq/bitcoin-sv-1.svg',
      marketCap: '803091682',
      price: '41.88368306997891',
      listedAt: 1541808000,
      tier: 1,
      change: '0.10',
      rank: 50,
      sparkline: [
        '41.85261785655585',
        '41.90050661607764',
        '42.03970874746106',
        '42.1676229935275',
        '42.23750268514209',
        '42.32465300473512',
        '42.321336327434096',
        '42.18542257342084',
        '42.18284035745824',
        '42.21017510849763',
        '42.259465782083566',
        '42.32989273503686',
        '42.40752406306638',
        '42.30934021288165',
        '42.09203594896302',
        '42.13804475325781',
        '42.15680006416136',
        '42.40073111677118',
        '43.04097662624274',
        '42.909471914127295',
        '42.63277256079823',
        '42.186311463927794',
        '42.07026256309879',
        '42.01938906549094',
        '41.96544904460127',
      ],
      lowVolume: false,
      coinrankingUrl:
        'https://coinranking.com/coin/VcMY11NONHSA0+bitcoinsv-bsv',
      '24hVolume': '87967925',
      btcPrice: '0.00251714741822506',
    },
  ];

  // eslint-disable-next-line operator-linebreak
  const coins =
    useSelector((state) => state.coinData?.data?.coins) ?? oldCoinData;
  const loading = useSelector((state) => state.coinData.loading);
  const [coinName, setCoinName] = useState('');
  // const [offset, setOffset] = useState(0);

  const handleChange = (event) => {
    event.preventDefault();
    setCoinName(event.target.value);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCoins({ coinName: coinName, limit: 100 }));
  }, [coinName]);

  const loader = () => (
    <div>
      <Container id="section1">
        <div className={classes.homepageHeading}>
          <Typography variant="h4" className="gradient__text">
            CryptoCurrencies
          </Typography>
        </div>
        <br />
        <div className={classes.searchBar}>
          <Paper component="form" className={classes.papperRoot}>
            <InputBase
              onChange={handleChange}
              value={coinName}
              className={classes.input}
              placeholder="Search Cryptocurrencies"
              inputProps={{ 'aria-label': 'Search Cryptocurrencies' }}
            />
            <IconButton className={classes.iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>

        <br />
        <Grid
          container
          spacing={3}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((currency) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={currency.uuid}>
              <Card className={classes.cardRoot} elevation={7}>
                <CardHeader
                  avatar={{
                    ...(
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    ),
                  }}
                  title={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <Skeleton
                      animation="wave"
                      height={10}
                      width="80%"
                      style={{ marginBottom: 6 }}
                    />
                  }
                  subheader={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    {
                      ...(
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      ),
                    }
                  }
                />

                <Divider />
                <CardContent>
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />

                  <br />

                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />

                  <br />

                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />

                  <br />

                  <Skeleton
                    animation="wave"
                    height={10}
                    width="80%"
                    style={{ marginBottom: 6 }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );

  if (loading && !(coins && coins?.length > 0)) return loader();

  // const fetchMoreData = () => {
  //   const pageOffset = offset + 20;
  //   setOffset(pageOffset);
  //   dispatch(fetchCoins({ coinName: coinName, offset: pageOffset }));
  // };

  return (
    <Container>
      <div className={classes.homepageHeading}>
        <Typography variant="h4" className="gradient__text">
          CryptoCurrencies
        </Typography>
      </div>
      <br />
      <div className={classes.searchBar}>
        <Paper component="form" className={classes.papperRoot}>
          <InputBase
            onChange={handleChange}
            value={coinName}
            className={classes.input}
            placeholder="Search Cryptocurrencies"
            inputProps={{ 'aria-label': 'Search Cryptocurrencies' }}
          />
          <IconButton className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>

      <br />

      {/* <div
        id="scrollableDivForActivity"
        className={classes.infiniteScrollContainer}
      > */}
      {/* <InfiniteScroll
          className={classes.infiniteScrollInner}
          dataLength={coins.length}
          next={fetchMoreData}
          hasMore
          hasChildren={false}
          // scrollableTarget="scrollableDivForActivity"
          endMessage="Data Ends Here"
          loader={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignContent: 'center',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <CircularProgress />
            </div>
          }
        > */}
      <Grid
        container
        spacing={3}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: '1rem',
          paddingBottom: '1rem',
        }}
      >
        {coins?.map((currency) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={currency.uuid}>
            <CryptocurrencyCards
              key={currency.uuid}
              rank={currency.rank}
              name={currency.name}
              icon={currency.iconUrl}
              price={currency.price}
              marketCap={currency.marketCap}
              change={currency.change}
              uuid={currency.uuid}
            />
          </Grid>
        ))}
      </Grid>
      {/* </InfiniteScroll> */}
      {/* </div> */}
    </Container>
  );
};

export default CryptoPage;
