import { fork, takeLatest, all, call, put } from 'redux-saga/effects';
import {
  fetchNewsStart,
  fetchNewsSuccess,
  fetchNewsFailed,
} from '../actions/newsAction';
// import config from '../api/config';
// import { EIPP_CONTEXT_PATH } from '../api/constants';
import { getNews } from '../api/newsApi';

function* workerForFetchNews(action) {
  try {
    yield put(fetchNewsStart());
    const response = yield call(getNews, action.payload);

    yield put(fetchNewsSuccess(response?.data));
  } catch (err) {
    yield put(fetchNewsFailed());
  }
}

function* watcherForFetchNews() {
  yield takeLatest('FETCH_NEWS', workerForFetchNews);
}

export default function* newsSaga() {
  yield all([fork(() => watcherForFetchNews())]);
}
